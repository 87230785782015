import { usePathname } from 'next/navigation';

const useNavigationColor = () => {
  const pathname = usePathname();

  const mainPaths = ['/active', '/borrow', '/lend', '/collections', 'my-loans', '/portfolio', 'nft-top-sales', 'crypto-prices', '/loans'];

  const isContained = mainPaths.some((path) => {
    if (path === '/loans') return pathname?.startsWith(path);

    return pathname?.endsWith(path);
  });

  if (isContained) return true;

  return false;
};

export default useNavigationColor;
