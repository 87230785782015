import { useRef, useState } from 'react';

import { Editor } from '@tinymce/tinymce-react';
import toast from 'react-hot-toast';

import { getUrlMetada } from '../../api/chatAPI';
import { useTheme } from '../../hooks/useTheme';
import { isUrlLink } from '../../utils/helper';
import Spinner from '../Spinner';

interface ITinyEditorProps {
  handleEditorChange: (content: string) => void;
  handleSend: () => void;
  handleFiles: (e: File) => void;
  currenValue: string;
  editorHeight: number;
  handleEditorHeight: (height: number) => void;
  placeholder: string;
  contentStyles?: string;
}

const TinyEditor: React.FC<ITinyEditorProps> = ({ handleEditorChange, currenValue, handleFiles, placeholder, contentStyles }) => {
  const { mode } = useTheme();
  const editorRef = useRef(null);
  const [embedLoading, setEmbedLoading] = useState(false);
  const defaultStyles = `
  html {
    display : flex;
    align-items:center;
  }
  body { 
  width: 100%;
  height: 100%;
  font-family: Poppins; 
  color: ${mode === 'light' ? '#414141' : '#fff'};
  margin : 0px;
  overflow-x : hidden;
  background-color : ${mode === 'light' ? '#F5F6FB' : '#161616'};
  margin-top : -11px;
  margin-left : ${mode === 'dark' ? '6px' : '1px'} !important;
  }
  body::before {
    margin-top: 16px;
    color: ${mode === 'light' ? '#414141' : '#fff'} !important;
    left : ${mode === 'dark' ? '6px' : '1px'} !important;
  }
  a {
    color : #1160FF !important
  }
  span {
    color : #1160FF !important;
  }
  `;

  return (
    <>
      {embedLoading ? (
        <div className='flex w-full h-full justify-center items-center'>
          <div className='w-4 h-4'>
            <Spinner />
          </div>
        </div>
      ) : null}
      <Editor
        key={mode}
        ref={editorRef}
        onInit={(a) => a.target.focus()}
        init={{
          plugins: ['autolink', 'preview', 'link', 'autoresize'],
          height: 72,
          min_height: 72,
          max_height: 600,
          autoresize_bottom_margin: 0,
          resize: false,
          menubar: false,
          statusbar: false,
          toolbar: false,
          placeholder,
          contextmenu: false,
          paste_data_images: false,
          text_patterns: false,
          text_patterns_lookup: (ctx) => {
            const parentag = ctx.text;
            const regex = /#([^#\s]*)/g;

            const replacements: Record<string, string>[] = [];

            parentag.replace(regex, (match) => {
              const replacement = `<a>${match}</a>`;

              replacements.push({
                start: match,
                replacement,
              });
              return replacement;
            });

            return replacements;
          },
          target_list: [{ title: 'New page', value: '_blank' }],
          extended_valid_elements: 'a[href|target=_blank]',
          paste_preprocess: async (editor, args) => {
            // @ts-ignore
            args?.preventDefault();
            if (isUrlLink(args.content)) {
              const copy = args.content.slice();
              args.content = `<a href=${args.content}>${args.content}</a>`;
              try {
                setEmbedLoading(true);
                const url = await getUrlMetada({ url: copy });
                setEmbedLoading(false);
                if (url && Object.keys(url.og).length) {
                  args.content += `
              <div style="display: flex; justify-content: center; align-items: center; border: 1px solid #ccc; padding: 10px; margin: 10px 0px; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); flex-wrap: wrap;">
              <img src="${url.og.image}" alt="Thumbnail" style="width: 100px; height: 100px; object-fit: cover; border-radius: 8px; margin-right: 20px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);" />
              <div style="max-width: 200px;">
                <p style="font-weight: bold; overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2;">${url.og.title}</p>
                <p style="margin: 8px 0 0; overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2;">${url.og.description}</p>
              </div>
            </div>
              `;
                }
              } catch (e) {
                console.log(e);
              }
            }
            editor.insertContent(args.content);
          },
          content_style: `@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');  
          ${contentStyles ?? defaultStyles}
          `,
        }}
        toolbar=''
        onEditorChange={(a, editor) => {
          handleEditorChange(editor.getContent({}));
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.stopImmediatePropagation();
          e.stopPropagation();

          const allowedFormats = ['image/jpeg', 'image/png'];

          if (e.dataTransfer) {
            const { files } = e.dataTransfer;
            for (let i = 0; i < files.length; i++) {
              const reader = new FileReader();
              const currentFile = files[i];
              if (allowedFormats.includes(currentFile.type)) {
                reader.onload = (event) => {
                  if (event.target && event.target.result) {
                    fetch(event.target.result as string)
                      .then((res) => res.blob())
                      .then((blob) => {
                        const file = new File([blob], currentFile.name, { type: currentFile.type });
                        handleFiles(file);
                      });
                  }
                };
                reader.readAsDataURL(currentFile);
              } else {
                toast.error(`File '${currentFile.name}' is not in JPEG or PNG format.`);
              }
            }
          }
        }}
        value={currenValue}
        apiKey='fepo9v3nh68gkuk7acha441gro12t37us7o867v7tz34yzbm'
      />
    </>
  );
};

export default TinyEditor;
