import React from 'react';

import useLogout from '@/hooks/useLogout';

const LogoutButton: React.FC = () => {
  const handleLogout = useLogout();

  return (
    <button type='button' className='text-primary-blue-100 bg-primary/15 py-3 px-6 text-sm ss:text-base/5 font-medium rounded-[4px] hover:opacity-75 active:scale-95' onClick={handleLogout}>
      Logout
    </button>
  );
};

export default LogoutButton;
