import { useQuery } from '@tanstack/react-query';

import { getRequestBackend } from '@/api/loans';

export type TLoanToken = {
  id: number;
  name: string;
  symbol: string;
  address: string;
  createdAt: Date;
  updatedAt: Date;
}[];

export const useLoanTokens = () => {
  return useQuery<TLoanToken>({
    queryKey: ['LOAN_TOKEN'],
    queryFn: async () => {
      return getRequestBackend('/tokens');
    },
  });
};
