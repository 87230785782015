import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { createPortal } from 'react-dom';
import { useAccount, useDisconnect } from 'wagmi';

import BellIcon from '@/assets/icons/icon-bell.svg';
import CloseIcon from '@/assets/icons/icon-close.svg';
import FingerPrintIcon from '@/assets/icons/icon-fingerprint.svg';
import ProfileIcon from '@/assets/icons/icon-head.svg';
import WalletIcon from '@/assets/icons/icon-wallet.svg';
import { Alerts } from '@/components/dashboard/alerts/Alerts';
import { WalletModal } from '@/components/dashboard/portfolio/WalletModal';
import ChangeThemeButton from '@/components/shared/buttons/ChangeThemeButton';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import useConnectorLogo from '@/hooks/useConnectorLogo';
import useNavigationColor from '@/hooks/useNavigationColor';
import useOnClickOutside from '@/hooks/useOnClickOutside';
// import { useSoundMode } from '@/hooks/useSoundMode';
import { useTheme } from '@/hooks/useTheme';
import { fetchUserNotification } from '@/redux/alerts/alertSlice';
import { setShowAnimation } from '@/redux/auth/authSlice';
import { toggleMoreMenu } from '@/redux/modal/modalSlice';
import { togglePanel } from '@/redux/panel/panelSlice';
import { openWalletModal } from '@/redux/wallet/userSlice';
import { removeCookie } from '@/utils/cookies';
import { AWSImageConverter, cn } from '@/utils/helper';

import DetailsPanel from './DetailsPanel';
import PanelLayout from './PanelLayout';
import SearchPanel from './SearchPanel';

const TOGGLE_WALLET_MODAL = 'TOGGLE_WALLET_MODAL';
const TOGGLE_LOGOUT = 'TOGGLE_LOGOUT';
const TOGGLE_ALERT = 'TOGGLE_ALERT';
const TOGGLE_SETTINGS = 'TOGGLE_SETTINGS';
const TOGGLE_WALLET_DISCONNECT = 'TOGGLE_DISCONNECT';
const TOGGLE_CHAT_MODAL = 'TOGGLE_CHAT';

const initialState = {
  seeWalletModal: false,
  showLogout: false,
  alertShow: false,
  settings: false,
  showDisconnect: false,
  chatModal: false,
};

const reducer = (state: typeof initialState, action: { type: string }) => {
  switch (action.type) {
    case TOGGLE_WALLET_MODAL:
      return { ...state, seeWalletModal: !state.seeWalletModal };
    case TOGGLE_LOGOUT:
      return { ...state, showLogout: !state.showLogout };
    case TOGGLE_ALERT:
      return { ...state, alertShow: !state.alertShow };
    case TOGGLE_SETTINGS:
      return { ...state, settings: !state.settings };
    case TOGGLE_WALLET_DISCONNECT:
      return { ...state, showDisconnect: !state.showDisconnect };
    case TOGGLE_CHAT_MODAL:
      return { ...state, chatModal: !state.chatModal };
    default:
      return state;
  }
};

type TPanelState = 'Alerts' | 'Chat' | 'Details' | 'Search';
type InnerPannelState = 'Account' | 'Notifications' | 'Menu';

const SideBarNav: React.FC = () => {
  const { userDetail } = useAppSelector((state) => state.user);
  const { activePanel } = useAppSelector((state) => state.panel);
  const { walletModalOpen } = useAppSelector((state) => state.user);
  const { moreModal } = useAppSelector((state) => state.modal);
  const [state, dispatch] = useReducer(reducer, initialState);
  const appDispatch = useAppDispatch();
  const { notification } = useAppSelector((s) => s.alert);
  // const { soundMode, handleSoundMode } = useSoundMode();
  const logOutRef = useRef<HTMLDivElement>(null);
  const { isConnected, connector } = useAccount();
  const navColor = useNavigationColor();

  const { disconnect } = useDisconnect();
  const { mode } = useTheme();
  const [innerTab, setInnerTab] = useState<InnerPannelState | null>(null);
  const { activeConnectorLogo } = useConnectorLogo();
  const handleInnerTab = useCallback((tab: InnerPannelState | null) => {
    setInnerTab(tab);
  }, []);

  const toggleWalletModal = () => {
    dispatch({ type: TOGGLE_WALLET_MODAL });
  };
  const toggleWalletDisconnect = () => {
    dispatch({ type: TOGGLE_WALLET_DISCONNECT });
  };
  const walletDisconnect = useCallback(() => {
    disconnect({ connector });
    removeCookie('portfolio');
    import('../../../api/mixpanel').then((mod) => {
      mod.Mixpanel.track(`${connector?.name} - Disconnected`);
      mod.Mixpanel.reset();
    });
    appDispatch(setShowAnimation(false));
    toggleWalletDisconnect();
  }, [appDispatch, connector, disconnect]);

  const panels = useMemo(() => {
    return [
      {
        text: 'Alerts',
        component: <Alerts />,
        fullText: 'Notifications',
      },
      {
        text: 'Details',
        component: <DetailsPanel setInnerTab={handleInnerTab} innerTab={innerTab} />,
      },
      {
        text: 'Search',
        component: <SearchPanel />,
      },
    ];
  }, [handleInnerTab, innerTab]);

  const handleActivePanel = (label: TPanelState) => {
    appDispatch(togglePanel(label));
  };

  const selectedPanel = panels.find((panel) => panel.text === activePanel);
  const PanelComponent = selectedPanel?.component ?? <></>;
  const renderPanelContent = () => {
    return innerTab ? (
      <>{PanelComponent}</>
    ) : (
      <PanelLayout fullText={selectedPanel?.fullText} onClose={() => appDispatch(togglePanel(null))} name={selectedPanel?.text ?? ''}>
        {PanelComponent}
      </PanelLayout>
    );
  };

  const toggleLogout = () => {
    dispatch({ type: TOGGLE_LOGOUT });
  };

  useOnClickOutside(logOutRef, toggleLogout);

  useEffect(() => {
    appDispatch(fetchUserNotification());
  }, [appDispatch]);

  return (
    <div className={cn('dark-widget dark:border-midnight-gray flex justify-end items-center gap-2 ss:gap-3 lg:justify-center', navColor ? '' : 'dark:!bg-jet-black !bg-white')}>
      <ChangeThemeButton />
      {/* <button onClick={() => handleSoundMode(soundMode === 'on' ? 'off' : 'on')} className='w-8 ss:w-10 h-8 ss:h-10 flex justify-center items-center bg-white rounded-full dark:!bg-jet-black'>
        {soundMode === 'on' ? <IconSoundOn className='fill-coal-250 dark:!fill-white dark:[&_path]:!fill-white' /> : <IconSoundOff className='fill-coal-250 dark:!fill-white dark:[&_path]:!fill-white' />}
      </button> */}
      <button
        onClick={() => {
          handleActivePanel('Alerts');
          if (moreModal) {
            appDispatch(toggleMoreMenu());
          }
          if (activePanel === 'Alerts') {
            appDispatch(togglePanel(null));
          }
        }}
        className={cn('w-8 ss:w-10 h-8 ss:h-10 flex relative justify-center items-center rounded-full', navColor ? 'bg-white-100 dark:!bg-jet-black-100' : '!bg-light-blue-grey dark:!bg-char-coal lg:dark:!bg-jet-black', activePanel === 'Alerts' ? 'bg-primary [&_svg]:fill-white' : '')}
      >
        <BellIcon className='fill-coal-250 dark:!fill-white dark:[&_path]:!fill-white size-4' />
        {notification?.notifications.filter((notif) => notif.seen === 0).length ? <span className='bg-forest-green w-2 h-2 rounded-full absolute top-0 right-1'></span> : null}
      </button>
      <div className='relative rounded-full flex'>
        <button
          onClick={() => {
            if (!isConnected) {
              appDispatch(openWalletModal(true));
            } else {
              toggleWalletDisconnect();
            }
          }}
          className={cn('w-8 ss:w-10 h-8 ss:h-10 justify-center items-center rounded-full flex', navColor ? 'bg-primary/15 dark:!bg-primary/15' : '!bg-primary/15 dark:!bg-primary/15 lg:dark:!bg-primary/15')}
        >
          {state.showDisconnect ? <CloseIcon /> : <WalletIcon className={'fill-primary dark:!fill-primary dark:[&_path]:!fill-primary size-4'} />}
        </button>
        {state.showDisconnect && (
          <>
            <div className='absolute top-12 -right-10 z-40 px-[10px] py-3 border border-light-gray dark:border-char-coal bg-white dark:bg-jet-black rounded-b rounded-tl gap-4 flex-col hidden lg:flex'>
              <div className='p-3 flex justify-between bg-light-gray dark:bg-jet-black-100 rounded'>
                <div className='flex justify-center items-center gap-2'>
                  <img src={activeConnectorLogo} alt='pic' className='size-5 rounded-full object-cover' />
                  <span className='font-semibold'>{connector?.name}</span>
                </div>
                <FingerPrintIcon className='text-light-green' />
              </div>
              <div className='py-3 px-16 bg-coal-250 rounded text-white text-sm cursor-pointer' onClick={() => walletDisconnect()}>
                Disconnect
              </div>
            </div>
            {createPortal(
              <div className='lg:hidden block'>
                <div className='fixed z-50 top-[84px] right-[22px] px-[10px] py-3 bg-white border border-light-gray rounded-b rounded-tl flex gap-4 flex-col'>
                  <div className='p-3 flex justify-between bg-light-gray rounded'>
                    <div className='flex justify-center items-center gap-2'>
                      <img src={activeConnectorLogo} alt='pic' className='size-5 rounded-full object-cover' />
                      <span className='font-semibold'>{connector?.name}</span>
                    </div>
                    <FingerPrintIcon className='text-light-green' />
                  </div>
                  <div className='py-3 px-16 bg-coal-250 rounded text-white text-sm cursor-pointer' onClick={() => walletDisconnect()}>
                    Disconnect
                  </div>
                </div>
              </div>,
              document.body
            )}
          </>
        )}
        {/* <button
          onClick={() => {
            handleActivePanel('Search');
            if (moreModal) {
              appDispatch(toggleMoreMenu());
            }
            if (activePanel === 'Search') {
              appDispatch(togglePanel(null));
            }
          }}
          className={cn('w-8 ss:w-10 h-8 ss:h-10 flex justify-center items-center rounded-full bg-white  dark:!bg-jet-black lg:hidden', activePanel === 'Search' ? '[&_svg]:fill-primary' : '')}
        >
          <SearchIcon className='dark:!fill-white dark:[&_path]:!fill-white' />
        </button> */}
      </div>
      <div className='!bg-transparent'>
        <button
          onClick={() => {
            handleActivePanel('Details');
            if (moreModal) {
              appDispatch(toggleMoreMenu());
            }
            if (activePanel === 'Details') {
              appDispatch(togglePanel(null));
            }
          }}
          className={cn('w-8 ss:w-10 h-8 ss:h-10 flex justify-center items-center rounded-full', activePanel === 'Details' ? 'border-2 border-primary' : '', navColor ? 'bg-white dark:!bg-jet-black' : '!bg-light-blue-grey dark:!bg-char-coal lg:dark:!bg-jet-black')}
        >
          {userDetail?.profile_picture ? <img src={AWSImageConverter(userDetail.profile_picture)} alt='user-image' className='rounded-full w-full h-full' /> : <ProfileIcon className='size-8 rounded-full dark:[&_circle]:!fill-white' />}
        </button>
      </div>
      {walletModalOpen && createPortal(<WalletModal isModalShow={toggleWalletModal} />, document.body)}
      {activePanel && (
        <>
          <div className='absolute top-0 right-0 w-[320px] pb-20 bg-light-blue-grey h-screen px-6 overflow-y-auto scrollbar-hide hidden lg:block dark:!bg-char-coal/[0.82] backdrop-blur-[6.5px] z-20 border-l border-l-coal-border'>
            <div className='mt-5'>{renderPanelContent()}</div>
          </div>
          {createPortal(
            <div className={`${mode === 'dark' ? 'bg-jet-black' : 'bg-white'} -mt-[7px] fixed z-[100] w-full top-[85px] h-screen scrollbar-hide lg:hidden`}>
              <div className='h-[calc(100%-183px)]'>
                <div className={cn(mode === 'dark' ? '!bg-char-coal' : 'bg-light-blue-grey', 'h-full pt-4 rounded-b-[42px] px-6 overflow-auto flex flex-col')}>{renderPanelContent()}</div>
              </div>
            </div>,
            document.getElementById('general')!
          )}
        </>
      )}
    </div>
  );
};

export default SideBarNav;
