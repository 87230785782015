import { useEffect, useState } from 'react';

import { clearChannels, fetchChannels } from '@/redux/channel/channelSlice';
import { clearTags, fetchPostTags, globalTagSearch } from '@/redux/feed/feedSlice';
import { clearUsers, fetchNettyUsers } from '@/redux/profile/profileSlice';
import { extractHashtags } from '@/utils/helper';

import useQuoteLatest from './query/useQuoteLatest';
import { useAppDispatch, useAppSelector } from './redux';
import { useDebounce } from './useDebounce';

const useGlobalSearch = () => {
  const { channels, channelsLoading } = useAppSelector((state) => state.channel);
  const { usersLoading, users } = useAppSelector((state) => state.profile);
  const { tags, tagsLoading, searchPostTag } = useAppSelector((state) => state.feed);
  const [showChannels, setShowChannels] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const debouncedVal = useDebounce(searchValue);
  const { data: cryptoData } = useQuoteLatest(debouncedVal);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (debouncedVal) {
      const formattedSearchValue = extractHashtags(debouncedVal.toLowerCase());
      if (debouncedVal.startsWith('#')) {
        dispatch(globalTagSearch(formattedSearchValue));
      }
      setShowChannels(true);
      dispatch(
        fetchChannels({
          limit: 5,
          search: debouncedVal,
        })
      );
      dispatch(
        fetchNettyUsers({
          search: debouncedVal,
          limit: 5,
        })
      );
      dispatch(
        fetchPostTags({
          search: formattedSearchValue,
          limit: 5,
        })
      );
    } else {
      dispatch(globalTagSearch(null));
      setShowChannels(false);
      dispatch(clearUsers());
      dispatch(clearChannels());
      dispatch(clearTags());
    }
  }, [debouncedVal, dispatch]);

  useEffect(() => {
    if (!searchValue && searchPostTag) {
      setSearchValue(`#${searchPostTag}`);
    }
  }, [searchPostTag]);

  const handleChannelSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const resultsLoading = usersLoading === 'pending' || channelsLoading || tagsLoading === 'pending';
  const modifiedUsers =
    usersLoading === 'success'
      ? users.map((user) => {
          return {
            id: user.id,
            name: user.name,
            category: 'user',
            url: `/feed/${user.profile_url}`,
            img_url: user.profile_picture,
          };
        })
      : [];
  const modifiedChannels = !channels
    ? []
    : channels?.map((channel) => {
        return {
          id: channel.id,
          name: channel.name,
          category: 'channel',
          url: `/channel/${channel.slug}`,
          img_url: channel.img_url,
        };
      });
  const modifiedTags =
    tagsLoading === 'success'
      ? tags.map((item) => {
          return {
            id: item.id,
            name: item.tag,
            category: 'tags',
            url: `/hashtag/${encodeURIComponent(item.tag)}`,
            img_url: null,
          };
        })
      : [];
  const modifiedCryptoData = cryptoData
    ? {
        id: cryptoData.id,
        name: cryptoData.name,
        category: 'crypto',
        url: `/crypto-prices/${encodeURIComponent(cryptoData.slug)}`,
        img_url: `https://s2.coinmarketcap.com/static/img/coins/64x64/${cryptoData.id}.png`,
      }
    : null;

  const mergedResults = [...modifiedUsers, ...modifiedChannels, ...modifiedTags];
  if (modifiedCryptoData) {
    mergedResults.push(modifiedCryptoData);
  }

  return { showChannels, setShowChannels, mergedResults, resultsLoading, handleChannelSearch, searchValue };
};

export default useGlobalSearch;
