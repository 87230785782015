import toast from 'react-hot-toast';
import { Connector, useConnect } from 'wagmi';

import MobileWallet from '@/components/mobile-wallet';
import { useAppDispatch } from '@/hooks/redux';
import { setShowAnimation } from '@/redux/auth/authSlice';
import { togglePanel } from '@/redux/panel/panelSlice';
import { openWalletModal } from '@/redux/wallet/userSlice';
import { regSw, subscribe } from '@/utils/helper';

import { PopularConenctors } from './PopularConnectors';

const WalletButtons = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const { connectors, connectAsync } = useConnect();
  const dispatch = useAppDispatch();

  const connectWalletCb = async (foundConnector: Connector) => {
    try {
      toast.success('Wallet connected successfully!');

      dispatch(setShowAnimation(true));
      dispatch(togglePanel(null));
      dispatch(openWalletModal(false));

      const activeAccounts = await foundConnector.getAccounts();

      if (!activeAccounts || activeAccounts.length === 0) {
        throw new Error('No active accounts found.');
      }

      const { Mixpanel } = await import('@/api/mixpanel');

      Mixpanel.track('Wallet Connected', {
        wallet_connect_method: foundConnector.name,
        wallet_address: activeAccounts[0],
        wallet_connection_state: true,
      });

      Mixpanel.registerOnce({
        first_wallet_connect_date: new Date(),
        ignore: false,
      });

      Mixpanel.increment('total_wallet_connects');
      Mixpanel.setProperty({ Demography: 'Stuff' });

      try {
        const serviceWorkerReg = await regSw();
        await subscribe(serviceWorkerReg);
      } catch (swError) {
        toast.error('Failed to subscribe to notifications.');
        console.error(swError);
      }
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : 'An unexpected error occurred.';
      toast.error(errorMessage);
      console.error(e);
    }
  };

  const connectWalletHandler = async (name: string) => {
    const foundConnector = connectors.find((conn) => conn.name === name);

    if (!foundConnector) {
      toast.error(`Connector ${name} not found`);
      return;
    }

    try {
      await connectAsync({ connector: foundConnector });
      await connectWalletCb(foundConnector);
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : 'An unexpected error occurred.';
      toast.error(errorMessage);
      console.error(e);
    }
  };

  return <div className='flex flex-col gap-[10px] rounded dark:!bg-transparent z-10'>{isMobile ? <MobileWallet onConnect={connectWalletHandler} /> : <PopularConenctors onClick={connectWalletHandler} />}</div>;
};

export default WalletButtons;
