import React, { useRef } from 'react';

import type { ClassValue } from 'clsx';
import { createPortal } from 'react-dom';

import CloseIcon from '@/assets/icons/icon-close-thick.svg';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { cn } from '@/utils/helper';

interface IPopupProps {
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
  closeClassname?: ClassValue;
  wrapperClassname?: ClassValue;
  className?: string;
}

const Popup: React.FC<IPopupProps> = ({ isOpen, children, onClose, closeClassname, className, wrapperClassname }) => {
  const popupRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(popupRef, () => onClose());

  return isOpen
    ? createPortal(
        <div data-slot='popup' className={cn(isOpen ? 'fixed inset-0 flex sm:items-center justify-center z-[9999999] opacity-100 backdrop-blur-md overflow-y-auto ' : 'hidden', 'my-modal', wrapperClassname)}>
          <div className={cn('rounded-lg relative dark:border dark:border-midnight-gray dark:!bg-transparent w-max bg-light-blue-grey mt-10 sm:mt-0 h-max overflow-x-hidden overflow-y-auto mx-4 sm:mx-0', className)}>
            <button className={cn('bg-shade-blue-grey w-[42px] h-[42px] flex justify-center items-center rounded-full absolute', closeClassname)} onClick={onClose}>
              <CloseIcon />
            </button>
            <div ref={popupRef}>{children}</div>
          </div>
        </div>,
        document.getElementById('general')!
      )
    : null;
};

export default Popup;
