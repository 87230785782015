import { useQuery } from '@tanstack/react-query';
import { useAccount } from 'wagmi';

import { getFloorPrice } from '@/utils/floorPrice';
import { GET_WALLET_ALL_NFTs } from '@/utils/queryKey';
import { getWalletAllNFTs } from 'simplehash_api/walletApi';

import useCurrency from './useCurrency';

export const useSpecificWalletAllNFTs = ({ walletAddress }: { walletAddress: `0x${string}` | undefined }) => {
  const { convertEthToUsd } = useCurrency();

  return useQuery({
    queryKey: [GET_WALLET_ALL_NFTs, walletAddress],
    queryFn: () => getWalletAllNFTs({ wallet_addresses: walletAddress! }),
    enabled: !!walletAddress,
    select: (data) => {
      return convertEthToUsd(data.reduce((agg, nft) => agg + (getFloorPrice(nft.collection.floor_prices, false, true) || 0), 0));
    },
  });
};

const useWalletAllNFTs = () => {
  const { address: walletAddress } = useAccount();

  return useSpecificWalletAllNFTs({ walletAddress });
};

export default useWalletAllNFTs;
