import { useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';
import { Toaster } from 'react-hot-toast';

import LiveEthGasInfoBox from '@/components/shared/LiveEthGasInfoBox';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import useAuthenticate from '@/hooks/useAuthenticate';
import { useTheme } from '@/hooks/useTheme';
import { cn } from '@/utils/helper';

import SEO from '../../SEO';
import { socialMediaIcons } from '../constants';
import Footer from '../Footer';
import SignedoutNavbar from '../Navbar';
import Navbar from '../navs/Navbar';
import Sidebar from '../navs/Sidebar';

const DashboardLayout = dynamic(() => import('./DasboardLayout'));
interface IGeneralLayout {
  children: React.ReactNode;
  isPrivate: boolean;
  isPublic: boolean;
  RenderComponent?: React.ComponentType | (() => React.ReactNode);
  CustomNavbar?: () => React.ReactNode;
  customStyle?: {
    navbar: string;
    container: string;
  };
  hideFooter?: boolean;
  signedInDesign?: boolean;
  useHideDefaultSidebarSections?: () => boolean;
  disableMaxWidth?: boolean;
  hideSocialAndGas?: boolean;
}
const GeneralLayout: React.FC<IGeneralLayout> = ({ children, isPrivate, isPublic, RenderComponent, CustomNavbar, signedInDesign, customStyle, hideSocialAndGas, useHideDefaultSidebarSections }) => {
  const { isAuth } = useAuthenticate();
  const { dynamicMetadata } = useAppSelector((state) => state.news);

  const [isAuthLocal, setISauthLocal] = useState<boolean | null>(null);

  const { mode } = useTheme();
  const dispatch = useAppDispatch();
  const router = useRouter();

  let metaData;

  if (dynamicMetadata) {
    metaData = { ...dynamicMetadata };
  }
  const MetaComponent = metaData && <SEO contentType={metaData.contentType} image={metaData.image} title={metaData.title} description={metaData.description} />;

  useEffect(() => {
    if (isAuth !== null) {
      setISauthLocal(isAuth);
    }
  }, [dispatch, isAuth]);

  if (isAuthLocal === null) {
    return MetaComponent || null;
  }

  if (isPublic && isAuth) {
    router.push('/loans/active');
    return null;
  }

  if (isPrivate && !isAuth) {
    router.push('/');
    return null;
  }

  return (
    <div id='general' className={`${mode} bg-white dark:bg-jet-black-50`}>
      <Toaster
        position='top-center'
        containerClassName='[&>div>div]:dark:!bg-jet-black dark:!bg-transparent dark:m-auto !top-[150px] lg:!top-[85px] bg-transparent [&>div]:dark:!bg-transparent'
        toastOptions={{
          className: 'dark:border dark:border-jet-black dark:bg-jet-black',
          loading: {
            className: '[&>div>div]:dark:!border-r-[#616161] [&>div>div]:dark:border-[#e0e0e0]',
          },
          error: {
            className: 'dark:border dark:border-midnight-gray [&>div>div>div]:dark:bg-[#ff4b4b]',
          },
        }}
      />
      {MetaComponent}
      <div className='hidden lg:block'>{/* <VLWrapper /> */}</div>
      <div>
        {isAuth ? (
          <DashboardLayout RenderComponent={RenderComponent} CustomNavbar={CustomNavbar} customStyle={customStyle} useHideDefaultSidebarSections={useHideDefaultSidebarSections}>
            {children}
          </DashboardLayout>
        ) : (
          <>
            <div className='flex'>
              {signedInDesign && <Sidebar />}
              <div className={cn('lg:min-h-fit flex-1 flex-col scrollbar-hide overflow-auto border-r col-span-3 row-span-1 lg:h-screen lg:pt-0 lg:flex', 'min-h-screen')}>
                {signedInDesign ? <Navbar /> : <SignedoutNavbar />}
                {children}
              </div>
              {/* <MobileStickyBottomNav dark={true} /> */}
            </div>
            {!signedInDesign && <Footer />}
          </>
        )}
      </div>
      {!hideSocialAndGas && (
        <>
          <div className='flex gap-6 fixed bottom-[30px] dark:bg-jet-black-100 bg-white-100 px-4 py-2 rounded left-28'>
            {socialMediaIcons.map((social) => {
              const SocialIcon = social.icon;
              return (
                <a href={social.path} target='_blank' key={social.path} className='flex justify-center items-center relative z-[1]' rel='noreferrer'>
                  <SocialIcon className='dark:[&_path]:fill-white [&_path]:fill-coal-250' />
                </a>
              );
            })}
          </div>
          <div className='flex justify-center fixed bottom-[30px] right-[30px]'>
            <LiveEthGasInfoBox />
          </div>
        </>
      )}
    </div>
  );
};

export default GeneralLayout;
