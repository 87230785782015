import { cn } from '@/utils/helper';

interface IToggleProps {
  value: boolean;
  isGrey?: boolean;
  onSwitch: (value: boolean) => void;
}

const ToggleButton: React.FC<IToggleProps> = ({ value, onSwitch, isGrey = false }) => {
  return (
    <label className={cn('relative inline-flex items-center cursor-pointer w-11 h-5 dark:!bg-char-coal ', isGrey ? 'lg:dark:!bg-char-coal' : 'lg:dark:!bg-jet-black')}>
      <input type='checkbox' className='sr-only peer' checked={value} readOnly />
      <button
        type='button'
        onClick={() => onSwitch(value)}
        className={cn(
          "w-full h-full bg-grey-lavender rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-[21px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[3px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[13px] after:w-[13px] after:transition-all dark:border-gray-600 peer-checked:bg-primary dark:!bg-jet-black",
          isGrey ? 'lg:dark:!bg-jet-black' : 'lg:dark:!bg-char-coal'
        )}
      ></button>
    </label>
  );
};

export default ToggleButton;
