import { useState } from 'react';

import { ConnectKitButton, useModal } from 'connectkit';

import { PopularConenctors } from '../dashboard/portfolio/PopularConnectors';

interface IMobileWalletProps {
  onConnect: (name: string) => void;
}

function MobileWallet({ onConnect }: IMobileWalletProps) {
  const [connectorName, setConnectName] = useState<string>('');

  useModal({
    onConnect: () => onConnect(connectorName),
  });

  return (
    <ConnectKitButton.Custom>
      {({ show }) => (
        <PopularConenctors
          onClick={(connector) => {
            setConnectName(connector);
            show?.();
          }}
          disableLoadingFunctionality
        />
      )}
    </ConnectKitButton.Custom>
  );
}
export default MobileWallet;
