import { useEffect } from 'react';

import moment from 'moment';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';

import { handleInvites } from '@/api/chatAPI';
import { notificationList } from '@/components/layouts-and-navs/constants';
import Accordion from '@/components/layouts-and-navs/navs/Accordion';
import { Loader } from '@/components/Loader';
import { useSocket } from '@/components/providers/socket-provider';
import Button from '@/components/shared/buttons/Button';
import ToggleButton from '@/components/shared/buttons/ToggleButton';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import useNotification from '@/hooks/useNotification';
import { useTheme } from '@/hooks/useTheme';
import { markSeeNotifAction } from '@/redux/alerts/alertSlice';
import { fetchChannelInvites, handleInvitesAction } from '@/redux/channel/channelSlice';
import { TInviteCallback } from '@/redux/channel/model';
import { cn } from '@/utils/helper';
import { INVITE_ACCEPT } from 'socket/channel/constant';

import AlertCard from './AlertCard';

const notificationData = [
  {
    category: 'sale',
    seen: 1,
    title: 'Flash Sale Alert!',
    description: 'Limited-time discount on exclusive items.',
    name: 'Limited Edition Watch',
    saleDate: '2025-03-10',
    collection_name: 'Luxury Watches',
    floor_price: 299.99,
    createdAt: '2025-03-07T12:00:00Z',
    updatedAt: '2025-03-07T12:30:00Z',
  },
  {
    category: 'announcement',
    seen: 0,
    title: 'New Collection Released',
    description: 'Check out our latest fashion collection.',
    collection_name: 'Spring 2025',
    createdAt: '2025-03-06T10:00:00Z',
    updatedAt: '2025-03-06T10:30:00Z',
  },
];
const Alerts = () => {
  const { invites, invitesLoading } = useAppSelector((state) => state.channel);
  const { userDetail } = useAppSelector((state) => state.user);
  const { topBanner } = useAppSelector((state) => state.modal);
  const { mode } = useTheme();
  // const router = useRouter();
  // const { socket } = useSocket();
  const { notification, handleSwitchToggle, loading, toggleSwitch } = useNotification();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(markSeeNotifAction());
    dispatch(fetchChannelInvites());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchChannelInvites());
  }, [dispatch]);

  // const acceptOrRejectInvite = async (body: Parameters<typeof handleInvites>[0] & { slug: string; userId: number; senderId: number }) => {
  //   socket?.emit(INVITE_ACCEPT, body, (res: TInviteCallback) => {
  //     if ('error' in res) {
  //       toast.error(res.error);
  //     }
  //     if ('data' in res) {
  //       router.push(`/channel/${body.slug}`);
  //     }
  //   });
  //   dispatch(handleInvitesAction({ inviteId: body.inviteId, status: body.status }));
  // };

  return (
    <div className={cn('mt-5', topBanner ? 'mt-20 md:mt-14' : '')}>
      <span className={`${mode === 'dark' ? 'bg-char-coal text-white' : 'text-coal-250 bg-light-blue-grey'} pt-6 text-[22px] font-semibold text-center z-10 absolute top-0 w-full left-0 lg:hidden`}>Alerts</span>
      <div className='flex flex-col gap-4'>
        {/* {
          // @ts-ignore
          loading ? (
            <Loader />
          ) : (
            notification?.notifications?.map((item, i) => {
              return <AlertCard key={i} data={item} />;
            })
          )
        } */}
        {!notification?.notifications?.length ? (
          <span className='text-sm'>There are no notifications to display</span>
        ) : (
          notificationData?.map((item, i) => {
            return <AlertCard key={i} data={item} />;
          })
        )}
      </div>
    </div>
  );
};

export { Alerts };
