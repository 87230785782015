import React from 'react';

import { useTheme } from '@/hooks/useTheme';
import { cn } from '@/utils/helper';

interface RightSidebarSettingsListProps {
  panels: {
    id: number;
    txt: string;
    value?: number | string;
    unit?: string;
    preFix?: boolean;
    postFix?: boolean;
    handler?: () => void;
  }[];
}

const RightSidebarSettingsList: React.FC<RightSidebarSettingsListProps> = ({ panels }) => {
  const { mode } = useTheme();

  return (
    <ul className={cn(mode === 'dark' ? 'border-b-coal-100' : 'border-light-blue-grey', 'pb-4 ss:pb-6 lg:pb-0 max-lg:border-b flex flex-col gap-4 ss:gap-7 flex-1')}>
      {panels.map(({ id, txt, value, preFix, postFix, unit }, idx) => {
        return (
          <li key={id} className='flex justify-between items-center gap-1'>
            <p className='text-sm ss:text-base/5 font-medium'>{txt}</p>
            <p className={`${idx === 0 ? '!text-green-250' : ''} text-sm ss:text-base/5 font-medium`}>
              {preFix && unit}
              {value}
              {postFix && unit}
            </p>
          </li>
        );
      })}
    </ul>
  );
};

export default RightSidebarSettingsList;
