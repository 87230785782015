import { BigNumber, ethers } from 'ethers';
import { toBn } from 'evm-bn';

const convertValueToWei = (value: number | string, decimals: number) => {
  return toBn(Number(value).toString(), decimals).toBigInt();
};

const convertWeiToEthers = (value: BigNumber | number) => {
  const weiInEthers = ethers.utils.formatEther(value.toString());
  return Number(weiInEthers);
};

const convertWeiToToken = (weiAmount: ethers.BigNumberish, decimals: number) => {
  return ethers.utils.formatUnits(weiAmount, decimals);
};

export { convertValueToWei, convertWeiToEthers, convertWeiToToken };
