import { memo } from 'react';

import ReactPlayer from 'react-player';
import { Navigation } from 'swiper/modules';
import { SwiperSlide, Swiper } from 'swiper/react';
import 'swiper/css';

import NextIcon from '@/assets/icons/icon-next.svg';
import PrevIcon from '@/assets/icons/icon-prev.svg';
import CloseIcon from '@/assets/icons/icon-xmark-solid.svg';
import { AWSImageConverter, cn } from '@/utils/helper';

interface IPostFilesProps {
  selectedFiles: { file: string; fileType: string }[];
  deleteSelected: (file: string) => void;
  deleteSelectedGif: () => void;
  selectedGif: string | null;
}

const PostFiles: React.FC<IPostFilesProps> = ({ selectedFiles, deleteSelected, selectedGif, deleteSelectedGif }) => {
  const swiperNextClassName = 'news-swiper-next-News';
  const swiperPrevClassName = 'news-swiper-prev-News';
  return (
    <div className='relative mt-3 mx-3 lg:mx-0'>
      <button className={cn('size-7 !bg-primary rounded-full flex justify-center items-center absolute -left-3 top-1/2 z-10 disabled:!bg-btn-disabled', swiperPrevClassName)}>
        <PrevIcon />
      </button>
      <button className={cn('size-7 !bg-primary rounded-full flex justify-center items-center absolute -right-3 top-1/2 z-10 disabled:!bg-btn-disabled', swiperNextClassName)}>
        <NextIcon />
      </button>
      <Swiper
        modules={[Navigation]}
        navigation={{
          nextEl: `.${swiperNextClassName}`,
          prevEl: `.${swiperPrevClassName}`,
          disabledClass: 'swiper-button-disabled',
        }}
        spaceBetween={24}
        slidesPerView={selectedFiles.length > 1 ? 2 : 1}
        className='w-[80%] lg:w-full h-[200px] lg:h-[356px]'
      >
        {selectedFiles.map((file) => {
          return (
            <SwiperSlide key={file.file}>
              <div className='rounded relative w-full h-full'>
                {file.fileType.startsWith('image') ? <img alt='post-image' src={AWSImageConverter(file.file)} className='object-cover bg-center rounded w-auto h-full' /> : file.fileType.startsWith('video') ? <ReactPlayer loop controls={true} width='100%' height='100%' url={AWSImageConverter(file.file)} /> : null}
                <button
                  className='bg-white rounded-full size-7 flex justify-center items-center absolute right-1 top-1'
                  onClick={() => {
                    deleteSelected(file.file);
                  }}
                >
                  <CloseIcon className='fill-coal-250 size-5' />
                </button>
              </div>
            </SwiperSlide>
          );
        })}
        {selectedGif ? (
          <SwiperSlide>
            <div className='relative h-[200px]'>
              <img src={selectedGif} alt='tenor-gif' className='w-full h-full' />
              <button
                className='bg-white rounded-full size-7 flex justify-center items-center absolute right-1 top-1'
                onClick={() => {
                  deleteSelectedGif();
                }}
              >
                <CloseIcon className='fill-coal-250 size-5' />
              </button>
            </div>
          </SwiperSlide>
        ) : null}
      </Swiper>
    </div>
  );
};

const MemoizedPostFiles = memo(PostFiles);
export default MemoizedPostFiles;
