import CalendarIcon from '@/assets/icons/icon-calendar-lg.svg';
import InstagramIcon from '@/assets/icons/icon-instagram-lg.svg';
import SymbolIcon from '@/assets/icons/icon-symbol-lg.svg';
import TimeIcon from '@/assets/icons/icon-time-lg.svg';

import lavie from './assets/homeImages/la-vie.png';
import lofink from './assets/homeImages/lofink.png';
import madzebra from './assets/homeImages/madzebra.png';
import shlat from './assets/homeImages/shlat.png';
import articleAd from './assets/images/articles-ads.png';
import currencyPic from './assets/images/currency.png';
import mutant from './assets/images/mutant.png';
import penguins from './assets/images/penguins.png';
import portfolioAd from './assets/images/portfolio-ad.png';
import collectionAd from './assets/images/upcoming-collection-ad.png';
import upcomingAd from './assets/images/upcoming-nft.png';
import xcopy from './assets/images/XCOPY.gif';

export const menuItems = [
  {
    name: 'Last 24 hours',
    date: '1d',
  },
  {
    name: 'Last 7 days',
    date: '7d',
  },
  {
    name: 'Last 30 days',
    date: '30d',
  },
];

export const cryptoMenuItems = [
  {
    name: 'Last 24 hours',
    date: 'dayPercent',
  },
  {
    name: 'Last 7 days',
    date: 'weekPercent',
  },
];

export const nftLoanCard = [
  {
    name: 'Mutant Ape Yacht Club',
    image: mutant,
    tokenId: '#3572',
    Loan: '1.25 ETH',
    payback: '1.296 ETH',
    APR: '15%',
    Duration: '90 Days',
  },
  {
    name: 'h-u-m-a-n by XCOPY',
    image: xcopy,
    tokenId: '#80413',
    Loan: '20 ETH',
    payback: '20.334 ETH',
    APR: '20%',
    Duration: '30 Days',
  },
  {
    name: 'Pudgy Penguins',
    image: penguins,
    tokenId: '#949',
    Loan: '10 ETH',
    payback: '10.295 ETH',
    APR: '18%',
    Duration: '60 Days',
  },
  {
    name: 'The Currency',
    image: currencyPic,
    tokenId: '#1215',
    Loan: '0.5 ETH',
    payback: '0.5164 ETH',
    APR: '20%',
    Duration: '60 Days',
  },
];

export const loanCondit = [
  {
    title: 'Connect Your Wallet',
    text: 'This allows you to select the NFT you would like to use as collateral for your loan.',
  },
  {
    title: 'Accept Loan Term',
    text: 'Get notified when a loan offer is made and select the best loan term for you.',
  },
  {
    title: 'Access More ETH',
    text: 'The crypto will be transferred to your wallet and your NFT will be stored safely. Repay your loan before the due date and get your NFT back.',
  },
];

export const loanBenefits = [
  {
    title: 'Get Access to faster liquidity',
    text: 'Most NFTs are illiquid which means selling them often takes time.',
    img: '/images/emoji-eye.svg',
  },
  {
    title: 'No paperwork',
    text: 'No credit score, paperwork is required.',
    img: '/images/emoji-glass.svg',
  },
  {
    title: 'Future blue chip NFTs',
    text: "Don't regret selling your NFTs",
    img: '/images/emoji-stars.svg',
  },
  {
    title: 'Earn more by lending crypto',
    text: 'Offer loan terms and use your idle crypto to earn more.',
    img: '/images/emoji-eye.svg',
  },
];

export const nftRules = [
  {
    title: 'How are my NFTs Protected?',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum efficitur vulputate neque id interdum. Donec sed odio lorem. Pellentesque at erat non nulla tempus tincidunt. Suspendisse sit amet aug.',
  },
  {
    title: 'How are my NFTs Protected?',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum efficitur vulputate neque id interdum. Donec sed odio lorem. Pellentesque at erat non nulla tempus tincidunt. Suspendisse sit amet aug.',
  },
  {
    title: 'How are my NFTs Protected?',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum efficitur vulputate neque id interdum. Donec sed odio lorem. Pellentesque at erat non nulla tempus tincidunt. Suspendisse sit amet aug.',
  },
  {
    title: 'How are my NFTs Protected?',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum efficitur vulputate neque id interdum. Donec sed odio lorem. Pellentesque at erat non nulla tempus tincidunt. Suspendisse sit amet aug.',
  },
];

type Breadcrumb = {
  blue: string;
  text: string;
};

export const breadcrumbs: { [key: string]: Breadcrumb } = {
  '/nft-news': { blue: 'NFT', text: 'Netty News' },
  '/crypto-news': { blue: 'Crypto', text: 'Netty News' },
  '/nft-education': { blue: 'NFT', text: 'Education' },
  '/meet-the-creator': { blue: 'NFT', text: 'Interviews' },
  '/metaverse': { blue: 'Metaverse', text: 'Netty News' },
  '/podcasts': { blue: 'NettyWorth', text: 'Podcasts' },
};

export const cryptoParams = {
  '/nft-news': '/category?section=general&topic=NFT&source=Cointelegraph,Decrypt,Reuters,Benzinga,Coindesk,Cryptonews,TheBlock',
  '/crypto-news': '/category?section=alltickers',
};

const starterPlan = ['30 Days', 'Advertise Banners on Social Feed'];
const premiumPlan = ['60 Days', ...starterPlan.slice(1), 'Featured on Newsletter'];
const enterPrisePlan = ['90 Days', ...premiumPlan.slice(1), 'Featured Article', 'Podcast Interview'];
export const plans = [
  {
    planType: 'Starter Package',
    price: '$2,495.95 USD',
    limit: '30 Days',
    access: starterPlan,
    link: 'https://buy.stripe.com/fZe17eeQNe2V4lWbIV',
    name: 'Get In Touch',
  },
  {
    planType: 'Premium Package',
    price: '$4,995.95 USD',
    limit: '60 Days',
    access: premiumPlan,
    link: 'https://buy.stripe.com/9AQ5nu3854sldWweV8',
    name: 'Get In Touch',
  },
  {
    planType: 'Enterprise Package',
    price: '$9,995.95 USD',
    limit: '90 Days',
    access: enterPrisePlan,
    link: 'https://buy.stripe.com/9AQbLS385bUN4lW28e',
    name: 'Get In Touch',
  },
];
export const advertiseRules = ['Select your Advertising Package', 'Get In Touch with the NettyWorth team', 'Launch Your Marketing Campaign'];
export const NettyworthInfo = [
  {
    id: 1,
    Icon: InstagramIcon,
    description: 'Social Followers',
    value: 30000,
  },
  {
    id: 2,
    Icon: TimeIcon,
    description: 'Engagement Time',
    value: '4:54',
  },
  {
    id: 3,
    Icon: CalendarIcon,
    description: 'Monthly Page Views',
    value: 50000,
  },
  {
    id: 4,
    Icon: SymbolIcon,
    description: 'Total User’s Wallet Value',
    value: 52000000,
    formatterOptions: {
      currency: 'USD',
      style: 'currency',
      notation: 'compact',
    },
  },
] as const;

export const nettyNotice = ['Thousands of Monthly Visitors & Growing', 'Premium Ad space on our site', 'Featured Article on Netty News', 'Access Social Media of 30,000+', 'New York City Time Square Digital Billboard', 'National Press Release'];
export const advertisePages = [
  {
    title: 'Articles & Podcasts ADS',
    img: articleAd,
  },
  {
    title: 'Upcoming Collection Feature AD',
    img: collectionAd,
  },
  {
    title: 'Upcoming NFT AD',
    img: upcomingAd,
  },
  {
    title: 'Portfolio Page Banner',
    img: portfolioAd,
  },
];
export const homeFeedback = [
  {
    name: '@Madzebra N0Fx',
    text: 'Wow. <a class="cursor-pointer" href="https://twitter.com/nettyworthapp" target="_blank"><span class="text-primary font-normal text-sm lg:text-xl">@nettyworthapp</span></a> portfolio value tool is impressive. Beyond that, it has live data on current market prices. Recommended, I&apos;ll shill to friends.',
    img: madzebra,
  },
  {
    name: '@C_lofink',
    text: 'I wish my value was higher. but super dope tool thank you... Also, no ledger signing was required so felt safe <a class="cursor-pointer" href="https://twitter.com/nettyworthapp" target="_blank"><span class="text-primary font-normal text-sm lg:text-xl">@nettyworthapp</span></a>',
    img: lofink,
  },
  {
    name: '@Shlat_eth',
    text: 'I thought <a class="cursor-pointer" href="https://twitter.com/nettyworthapp" target="_blank"><span class="text-primary font-normal text-sm lg:text-xl">@nettyworthapp</span></a> was extremely easy to use and its amazing the data it shows.',
    img: shlat,
  },
  {
    name: '@la_vie02',
    text: '<a class="cursor-pointer" href="https://twitter.com/nettyworthapp" target="_blank"><span class="text-primary font-normal text-sm lg:text-xl">@nettyworthapp</span></a> I like the platform. could be a good tool in the future!!',
    img: lavie,
  },
];

export const staticChannels = [
  {
    id: 1,
    userId: 2,
    img_url: '/azuki-collectors.webp',
    name: 'Azuki Collectors',
    description: '',
    chanel_type: '',
    plan_type: '',
    share_links: false,
    isPrivate: false,
    invite: false,
    banner_image: '/azuki-collectors.webp',
    slug: '/azuki-collectors',
    createdAt: '',
    updatedAt: '',
    messages: [],
    subchannels: [],
    parentId: null,
  },
  {
    id: 2,
    userId: 2,
    img_url: '/bayc-collectors.webp',
    banner_image: '/azuki-collectors.webp',
    name: 'Bayc Collectors',
    description: '',
    chanel_type: '',
    plan_type: '',
    share_links: false,
    isPrivate: false,
    slug: '/azuki-collectors',
    invite: false,
    createdAt: '',
    updatedAt: '',
    messages: [],
    subchannels: [],
    parentId: null,
  },
];

export const staticMessages = [
  {
    room: 'general',
    author: 'RoboJane',
    message: 'Has anyone checked out the recent activity with BTC price movement?',
    authorImg: '/robocop.png',
    authorEmail: 'johndoe@example.com',
    id: 1,
    isEdit: false,
    createdAt: '22 minutes ago',
    updatedAt: '2023-11-08T19:16:00.000Z',
    isPinned: false,
  },
  {
    room: 'general',
    author: 'DegenAl',
    message: "It's not limited to BTC, other cryptocurrencies are picking up pace too.",
    authorImg: '/cat-nft.webp',
    authorEmail: 'janedoe@example.com',
    id: 2,
    isEdit: false,
    createdAt: '14 minutes ago',
    updatedAt: '2023-11-08T19:16:30.000Z',
    isPinned: false,
  },
  {
    room: 'programming',
    author: 'JulyGrullon',
    message: 'We might have experienced the bottom; the next bull run will be crazy.',
    authorImg: '/ape-yacht.webp',
    authorEmail: 'peterjones@example.com',
    id: 3,
    isEdit: false,
    createdAt: '10 minutes ago',
    updatedAt: '2023-11-08T19:17:00.000Z',
    isPinned: false,
  },
];

export const aboutNetty = [
  {
    text: 'Join the Conversation',
    imgUrl: '/smile.svg',
  },
  {
    text: 'View Total Wallet Value',
    imgUrl: ' /smile-wow.svg',
  },
  {
    text: 'Monthly Giveaways',
    imgUrl: '/smile-dollar.svg',
  },
];

export const editorDarkMode = `
  html {
  display: flex;
  flex-direction: column;
  justify-content: center;
  }
  body {
    font-family: Poppins;
    color: white;
    background-color: #0A0A0A;
    caret-color: white;
  }
  body::before {
    color : white !important;
    left: 5px !important;
    top: 31%;
  }
  a {
    color: #1160FF;
  }
  ::placeholder {
    color: transparent;
  }
  iframe{
    background-color:transparent !important
  }
 @media (max-width:1024px){
  body{
    background-color: transparent;
  }
 }
`;
export const editorlight = `
  html {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  body::before {
    left: 5px !important;
    top: 31%;
  }
  body { 
  font-family: Poppins; 
  color: #414141;
  }
  `;

export enum unfishedOperations {
  ACCEPT_COUNTER_OFFER = 'unfinished-accept-counter-offer',
  ACCEPT_LOAN_REQUEST = 'unfinished-accept-loan-request',
}

export const SIMPLEHASH_CHAIN = process.env.NEXT_PUBLIC_SIMPLEHASH_CHAIN!;
