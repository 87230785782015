import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import useAuthenticate from '@/hooks/useAuthenticate';
import { useTheme } from '@/hooks/useTheme';
import { toggleMoreMenu } from '@/redux/modal/modalSlice';
import { togglePanel } from '@/redux/panel/panelSlice';
import { cn } from '@/utils/helper';

import { MORE_LEGAL_LINK_ITEMS, getInTouch, menuLinks, socialMediaIcons } from '../constants';

const MenuModal: React.FC = () => {
  const dispatch = useDispatch();
  const pathname = usePathname();
  const router = useRouter();
  const { isAuth } = useAuthenticate();
  const handleNavigate = (link: string, privateLink?: boolean) => {
    dispatch(toggleMoreMenu());
    if (privateLink && !isAuth) {
      router.push('/register');
    } else {
      router.push(link);
    }
    dispatch(togglePanel(null));
  };
  const { mode } = useTheme();
  return (
    <div className={cn(mode === 'dark' ? 'bg-char-coal [&_button]:bg-jet-black [&_span]:text-white  [&>span]:text-white' : 'bg-light-blue-grey ', ' sticky pb-20 scrollbar-hide bottom-0  overflow-auto rounded-t-[24px] z-[1000] flex flex-col items-center h-[calc(100vh-168px)] lg:hidden')}>
      <span className='text-coal-250 text-[22px] font-semibold mb-6 block'>Menu</span>
      <div className={`${mode === 'dark' ? 'border-char-coal' : 'border-y-shade-blue-grey'} border-y  w-full`}>
        <div className='grid grid-cols-3 gap-y-4 pt-[31px] pb-[34px] px-5 gap-x-[25px]'>
          {menuLinks.map(({ Icon, link, title, privateLink }) => {
            return (
              <button key={title} className={`${mode === 'light' ? 'bg-white' : 'bg-char-coal'}  rounded-lg max-w-[112px] w-full flex justify-center items-center flex-col gap-[15px] pt-6 pb-4 justify-self-center`} onClick={() => handleNavigate(link, privateLink)}>
                <Icon className={`w-5 h-5 ${mode === 'dark' ? 'fill-white [&>path]:fill-white [&>g]:fill-white stroke-white !text-white' : 'stroke-coal-250 fill-coal-250 [&>path]:fill-coal-250 [&>g]:fill-coal-250'}`} />
                <span className={`${mode === 'dark' ? 'text-white' : 'text-coal-250'} font-medium text-sm`}>{title}</span>
              </button>
            );
          })}
        </div>
      </div>
      <div className='grid grid-cols-2 w-full justify-items-center gap-[25px] py-[19px] px-5'>
        {getInTouch.map(({ Icon, title, link }, id) => {
          return (
            <button className={cn('flex max-w-[180px] justify-center w-full items-center gap-[10px] bg-white rounded  py-[18px]', id === 1 ? 'px-[22px]' : 'px-[9px]', id === 2 ? 'col-start-1 col-end-3' : '')} key={title} onClick={() => handleNavigate(link)}>
              <Icon className={`${mode === 'dark' ? 'fill-white [&_path]:fill-white' : 'fill-coal-250'} w-5 h-5 ${title === 'Docs' && (mode === 'dark' ? 'stroke-white [&_path]:fill-none' : 'stroke-coal-250')}`} />

              <span className='text-sm ss:text-base text-coal-250'>{title}</span>
            </button>
          );
        })}
      </div>
      <div className={`${mode === 'dark' ? 'border-char-coal [&>h3]:text-white' : 'border-y-shade-blue-grey'} pt-[22px] pb-[27px] w-full  border-y`}>
        <h3 className='text-base font-semibold text-coal-250 text-center'>Follow us</h3>
        <div className='flex gap-6 pt-4 justify-center'>
          {socialMediaIcons.map((social) => {
            const SocialIcon = social.icon;
            return (
              <a href={social.path} target='_blank' key={social.path} className='flex justify-center items-center' rel='noreferrer'>
                <SocialIcon className='fill-primary' />
              </a>
            );
          })}
        </div>
      </div>
      <div className={`${mode === 'dark' ? 'text-white [&_span]:text-white [&_p]:text-white [&_button]:!bg-transparent' : 'text-coal-250'} pt-[13px]`}>
        {/* <h3 className='text-base font-semibold text-center'>Legal</h3> */}
        <div className='flex gap-[17px] pt-[19px] justify-center items-center'>
          {MORE_LEGAL_LINK_ITEMS.map(({ path, link }) => {
            return (
              <button key={path} className='block' onClick={() => handleNavigate(path)}>
                <span className={cn('text-[14px] hover:text-primary', pathname.includes(path) ? 'text-primary' : '')}>{link}</span>
              </button>
            );
          })}
        </div>
        <div className='pt-4 flex gap-4 flex-wrap'>
          <p className='text-sm font-normal'>&copy; {new Date().getFullYear()} NettyWorth.</p>
          <p className='text-sm font-normal'>All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default MenuModal;
