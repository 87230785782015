import { useQuery } from '@tanstack/react-query';

import { getCryptoDetails } from '@/api/api';
import { IData } from '@/redux/crypto/model';
import { GET_QUOTE_LATEST } from '@/utils/queryKey';

const useQuoteLatest = (slug: string) => {
  return useQuery<IData>({
    queryKey: [GET_QUOTE_LATEST, slug],
    queryFn: async () => {
      const res = await getCryptoDetails({
        slug: slug ? slug.toLowerCase() : undefined,
      });
      return Object.values(res.data)[0];
    },
    enabled: !!slug,
  });
};

export default useQuoteLatest;
