import moment from 'moment';

import MenuIcon from '../../../assets/icons/icon-elipses.svg';
import { IAlert } from '../../../redux/alerts/model';
import { cn } from '../../../utils/helper';

interface AlertCardProps {
  data: IAlert;
}

const AlertCard: React.FC<AlertCardProps> = ({ data }) => {
  const notificationCategory: Record<string, string> = {
    Upcoming: 'Upcoming NFT ',
    News: 'Daily NettyWorth News ',
    NFTSales: 'Favourite NFT ',
  };

  const notifDescription: Record<string, string | undefined> = {
    Upcoming: `${data.name} - ${moment(data.saleDate).format('h A')}`,
    News: data.title,
    NFTSales: `${data.collection_name} - ${data.floor_price} ETH`,
  };

  return (
    <div className={cn('group rounded pl-[20px] pr-[17px] flex flex-col gap-[6px] pt-4 pb-[30px] dark:bg-jet-black', data.seen ? 'bg-shade-blue-grey' : 'bg-white')}>
      <div className={cn(data.seen ? 'bg-white' : 'bg-shade-blue-grey', 'max-w-[97px] !bg-transparent rounded')}>
        <p className='text-[11px]/[25px] font-semibold text-coal-250 dark:text-white rounded dark:bg-coal group-hover:bg-[#422D42] group-hover:text-primary text-center'>{moment(data.updatedAt).fromNow()}</p>
      </div>
      <div className='flex justify-between items-center mt-[9px]'>
        <p className='text-sm font-normal text-coal-250 group-hover:text-primary'>
          {notificationCategory[data.category] ? <span className='text-sm font-semibold group-hover:text-primary'>{notificationCategory[data.category]}</span> : null}
          {notifDescription[data.category] || <span className='text-sm font-normal group-hover:text-primary'>{data.description}</span>}
        </p>
        <button>
          <MenuIcon />
        </button>
      </div>
    </div>
  );
};
export default AlertCard;
