import ChannelIcon from '@/assets/icons/icon-channel.svg';
import CryptoPricesIcon from '@/assets/icons/icon-cryptho-logo.svg';
import DocsIcon from '@/assets/icons/icon-docs.svg';
import LoanIcon from '@/assets/icons/icon-dolar.svg';
import GraphIcon from '@/assets/icons/icon-graph.svg';
import IconListDots from '@/assets/icons/icon-listdots.svg';
import MessageIcon from '@/assets/icons/icon-message-dots.svg';
import MessagesIcon from '@/assets/icons/icon-message-small.svg';
import MoreIcon from '@/assets/icons/icon-more.svg';
import NewUserIcon from '@/assets/icons/icon-new-user.svg';
import NewsIconSmall from '@/assets/icons/icon-news-small.svg';
import NFTSalesIcon from '@/assets/icons/icon-nft-small.svg';
import MenuIcon from '@/assets/icons/icon-portfolio-new.svg';
import PortfolioIcon from '@/assets/icons/icon-portfolio-other.svg';
import RequestIcon from '@/assets/icons/icon-request.svg';
import RewardsIcon from '@/assets/icons/icon-rewards-other.svg';
import SDKIcon from '@/assets/icons/icon-sdk.svg';
import IntagramIcon from '@/assets/icons/icon-social-insta.svg';
import LinkedInIcon from '@/assets/icons/icon-social-ln.svg';
import TwitterIcon from '@/assets/icons/icon-social-x.svg';
import YoutubeIcon from '@/assets/icons/icon-social-youtube.svg';
import SuggestIcon from '@/assets/icons/icon-suggest.svg';
import TelegramIcon from '@/assets/icons/icon-telegram.svg';
import ToggleIcon from '@/assets/icons/icon-toggles.svg';
import WalletIcon from '@/assets/icons/icon-wallet-small.svg';

export const MORE_LINK_ITEMS = [
  {
    link: 'API/SDK',
    path: 'https://5vx6x80c2zf.typeform.com/to/No8JcIrA?typeform-source=docs.nettyworth.io',
    newTab: true,
  },
  {
    link: 'Docs',
    path: 'https://docs.nettyworth.io/docs/nettyworth',
    newTab: true,
  },
  {
    link: 'About us',
    path: 'https://docs.nettyworth.io/docs/team',
    newTab: true,
  },
  {
    link: 'Contact',
    path: '/contact',
    newTab: false,
  },
  // {
  //   link: 'Advertise',
  //   path: '/nft-advertising',
  // },
];

export const MORE_LINK_ITEMSMENU = [
  {
    link: 'Portfolio',
    path: '/',
  },
  {
    link: 'NFT Loans',
    path: '/loans/active',
  },
  {
    path: '/crypto-prices',
    link: 'Crypto',
  },
  {
    path: '/nft-news',
    link: 'Netty News',
  },
  {
    link: 'Upcoming',
    path: '/upcoming-Ethereum-nft-launches',
  },
  {
    path: '/nft-top-sales',
    link: 'NFT Sales',
  },
  {
    path: '/nft-education',
    link: 'Education',
  },
  {
    path: '/podcasts',
    link: 'Videos',
  },
];

export const MORE_LEGAL_LINK_ITEMS = [
  {
    link: 'Privacy Policy',
    path: '/privacy',
  },
  {
    link: 'Terms of service',
    path: '/service',
  },
];

export const MOBILE_MORE_LINK_ITEMS = [
  'Learn',
  'Feedback',
  'About us',
  'Contact',
  //  'Advertise',
  'Advertise Collection',
];

export const sideBarLinks = [
  {
    link: '/loans/active',
    Icon: LoanIcon,
    title: 'Loans',
  },
  {
    link: '/portfolio',
    Icon: GraphIcon,
    title: 'Portfolio',
  },
  // {
  //   link: '/feed',
  //   Icon: HomeIcon,
  //   title: 'Feed',
  // },
  // {
  //   link: '/upcoming-Ethereum-nft-launches',
  //   Icon: CalendarIcon,
  //   title: 'Drops',
  // },
  {
    link: 'https://rewards.nettyworth.io/',
    Icon: RewardsIcon,
    title: 'Rewards',
    newTab: true,
  },
  {
    link: '/feedback',
    Icon: SuggestIcon,
    title: 'Suggest',
  },
  // {
  //   link: '/communities',
  //   Icon: CommunityLgIcon,
  //   title: 'Communities',
  // },
];

export const mobileSideBarLinks = [
  {
    link: '/loans/active',
    Icon: LoanIcon,
    title: 'Loan',
    isPrivate: true,
    headerText: 'Loan',
    newTab: false,
  },
  {
    link: '/portfolio',
    Icon: GraphIcon,
    title: 'Portfolio',
    isPrivate: true,
    headerText: 'Portfolio',
    newTab: false,
  },
  // {
  //   link: '/crypto-prices',
  //   Icon: CryptoPricesIcon,
  //   title: 'Crypto',
  //   headerText: 'Crypto Market',
  //   isPrivate: true,
  //   newTab: false,
  // },
  // {
  //   link: '/nft-top-sales',
  //   Icon: NFTSalesIcon,
  //   title: 'NFT',
  //   headerText: 'NFT Sales',
  //   newTab: false,
  // },
  {
    link: 'https://rewards.nettyworth.io/',
    Icon: RewardsIcon,
    title: 'Rewards',
    isPrivate: true,
    headerText: 'Rewards',
    newTab: true,
    modal: false,
  },
  {
    link: '/feedback',
    Icon: SuggestIcon,
    title: 'Suggest',
    isPrivate: true,
    headerText: 'Suggest',
    newTab: false,
    modal: false,
  },
  {
    link: '',
    Icon: MoreIcon,
    title: 'Menu',
    modal: true,
    newTab: false,
  },
];

export const socialMediaIcons = [
  {
    path: 'https://twitter.com/nettyworthapp',
    icon: TwitterIcon,
    media: 'Twitter',
  },
  {
    path: 'https://t.me/+K1OvsbxoF8ozOGIx',
    icon: TelegramIcon,
    media: 'Telegram',
  },
  {
    path: 'https://www.linkedin.com/company/nettyworth',
    icon: LinkedInIcon,
    media: 'Linkedin',
  },
  {
    path: 'https://www.youtube.com/channel/UCS728rmdZt-IudjmVQOwN4g',
    icon: YoutubeIcon,
    media: 'Youtube',
  },
  {
    path: 'https://www.instagram.com/nettyworth.io',
    icon: IntagramIcon,
    media: 'Instagram',
  },
];

interface ILinks {
  link: string;
  title: string;
}
export const staticNavbarLinks: ILinks[] = [
  {
    link: '/login',
    title: 'Portfolio',
  },
  {
    link: '/loans/active',
    title: 'NFT Loans',
  },
  {
    link: '/crypto-prices',
    title: 'Crypto',
  },
  {
    link: '/nft-news',
    title: 'Netty News',
  },
  {
    link: '/upcoming-Ethereum-nft-launches',
    title: 'Upcoming',
  },
  {
    link: '/nft-top-sales',
    title: 'NFT Sales',
  },
  {
    link: '/podcasts',
    title: 'Videos',
  },
];
export const footerResources: ILinks[] = [
  {
    link: '/nft-news/what-are-nfts',
    title: 'What are NFTs',
  },
  {
    link: '/nft-news/nft-glossary-101',
    title: 'NFT Glossary',
  },
  {
    link: '/podcasts',
    title: 'Podcast',
  },
  {
    link: '/nft-education',
    title: 'Learn',
  },
];
export const footerUpcomings: ILinks[] = [
  {
    link: '/upcoming-Ethereum-nft-launches',
    title: 'Upcoming Ethereum NFTs',
  },
  {
    link: '/upcoming-Solana-nft-launches',
    title: 'Upcoming Solana NFTs',
  },
  {
    link: '/upcoming-Cardano-nft-launches',
    title: ' Upcoming Cardano NFTs',
  },
  {
    link: '/upcoming-free-nft-mint',
    title: 'Upcoming Free NFT Mints',
  },
  {
    link: '/nft-advertising',
    title: 'List your NFT Collection!',
  },
];

export const channelRoomNavigation = (channelId: string, access: boolean, subChannelLink?: string) => {
  const roomLinks = [
    // {
    //   label: 'dashboard',
    //   icon: MoreIcon,
    //   link: `/channel/${channelId}/dashboard`,
    // },
    {
      label: 'settings',
      icon: ToggleIcon,
      link: subChannelLink ?? `/channel/${channelId}/settings`,
    },
  ];
  if (access && !subChannelLink) {
    roomLinks.push({
      label: 'invite',
      icon: NewUserIcon,
      link: `/channel/${channelId}/invite`,
    });
  }
  return roomLinks;
};

export const menuLinks = [
  {
    link: '/loans/active',
    Icon: LoanIcon,
    title: 'Loans',
    headerText: 'Loans',
  },
  {
    link: '/portfolio',
    Icon: PortfolioIcon,
    title: 'Portfolio',
    headerText: 'Portfolio',
    private: true,
    privateLink: true,
  },
  {
    link: '/crypto-prices',
    Icon: CryptoPricesIcon,
    title: 'Crypto',
    headerText: 'Crypto Market',
  },
  {
    link: '/nft-top-sales',
    Icon: NFTSalesIcon,
    title: 'NFT',
    headerText: 'NFT Sales',
  },
  {
    link: 'https://rewards.nettyworth.io/',
    Icon: RewardsIcon,
    title: 'Rewards',
    newTab: true,
  },
  {
    link: '/api-sdk',
    Icon: (props: any) => SDKIcon({ ...props, stroke: 'white' }),
    title: 'API/SDK',
    headerText: 'API/SDK',
  },
  // {
  //   link: 'https://docs.nettyworth.io/docs/welcome',
  //   Icon: (props: any) => DocsIcon({ ...props, stroke: 'white' }),
  //   title: 'Docs',
  //   headerText: 'Docs',
  // },
  // {
  //   link: '/nft-advertising',
  //   Icon: AdvertiseIcon,
  //   title: 'Advertise',
  //   headerText: 'Advertise',
  // },
];
export const getInTouch = [
  {
    link: '/feedback',
    Icon: RequestIcon,
    title: 'Feature Request',
  },
  {
    link: '/contact',
    Icon: MessageIcon,
    title: 'Get in Touch',
  },
  {
    link: 'https://docs.nettyworth.io/docs/nettyworth-white-paper',
    Icon: DocsIcon,
    title: 'Docs',
  },
];

export const moreLinksMobile = [
  {
    link: '/channel',
    Icon: ChannelIcon,
    title: 'Channel',
    headerText: 'Channel',
  },
  {
    link: '/contact',
    Icon: MessagesIcon,
    title: 'Get in Touch',
    headerText: 'Get in Touch',
  },
  {
    link: '/crypto-news',
    Icon: NewsIconSmall,
    title: 'Netty News',
    headerText: 'Netty News',
  },
  {
    link: '/feedback',
    headerText: 'Feature Request',
    Icon: RequestIcon,
    title: 'Feature Request',
  },
  {
    link: '/portfolio/nfts',
    headerText: 'My Wallet',
    Icon: WalletIcon,
    title: 'My Wallet',
  },
];

export const filterNfts = [
  {
    id: 0,
    Icon: MenuIcon,
  },
  {
    id: 1,
    Icon: IconListDots,
  },
];
export const withoutHeader = ['/feed', '/portfolio', '/crypto-prices', '/nft-top-sales', '/feedback'];

export const notificationList = [
  // {
  //   label: 'Portfolio',
  //   value: 'News',
  // },
  {
    label: 'Email Notifications',
    additionalText: 'Please send me alert notification emails regarding my assets.',
    value: 'WalletEmails',
  },
  // {
  //   label: 'Crypto Watch List',
  //   value: 'CryptoSales',
  // },
  // {
  //   label: 'NFT Watch List',
  //   value: 'NFTSales',
  // },
  // {
  //   label: 'Upcoming NFTs',
  //   value: 'Upcoming',
  // },
] as const;
