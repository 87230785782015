import { useCallback, useState } from 'react';

import { useRouter } from 'next/navigation';
import toast from 'react-hot-toast';

import useUploadFiles from './mutation/useUploadFiles';
import { useAppDispatch, useAppSelector } from './redux';
import { useSocket } from '../components/providers/socket-provider';
import { fileLoadingAction } from '../redux/feed/feedSlice';
import { IPostData } from '../redux/feed/model';
import { CREATE_POST } from '../socket/post/constant';

const usePostSocket = () => {
  const { posts } = useAppSelector((state) => state.feed);
  const { userDetail } = useAppSelector((state) => state.user);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { socket } = useSocket();
  const [editorHeight, setEditHeight] = useState(72);
  const [postText, setPostText] = useState('');
  const [selectedFiles, setSelectFiles] = useState<{ file: string; fileType: string }[]>([]);
  const [selectedGif, setSelectedGif] = useState<string | null>(null);
  const { progress, mutateAsync, isPending: filesLoading } = useUploadFiles();

  const handleFileSelect = async (file: File) => {
    if (file) {
      const maxSizeInBytes = 512 * 1024 * 1024;
      if (selectedFiles.length >= 4) {
        toast.error('max 4 files allowed');
        return;
      }
      if (file.size <= maxSizeInBytes) {
        const formData = new FormData();
        formData.append('files', file);
        const ddt = await mutateAsync(formData);
        setSelectFiles((prevFiles) => [...prevFiles, ...ddt]);
      } else {
        toast.error('The selected file is too large. Please choose a file smaller than 512 MB.');
      }
    }
  };

  const deleteSelectedFile = useCallback((file: string) => {
    setSelectFiles((prevFiles) => {
      return prevFiles.filter((prFIle) => prFIle.file !== file);
    });
  }, []);

  const handlePostText = (text: string) => {
    setPostText(text);
  };

  const handleEditHeight = (height: number) => {
    setEditHeight(height);
  };

  const handleSelectGif = (gifUrl: string) => {
    setSelectedGif(gifUrl);
  };

  const deleteSelectedGif = useCallback(() => {
    setSelectedGif(null);
  }, []);

  const handleSendMessage = ({ replyToPostId, repostedId, quotedPostId, quoted, authoremail, authorname }: Partial<Pick<IPostData, 'replyToPostId' | 'repostedId' | 'quotedPostId' | 'quoted'>> & { authorname?: string; authoremail?: string }) => {
    const emptyContentpattern = /^<p>(?:&nbsp;|<br>|\s)*<\/p>$/;
    const isEmpty = emptyContentpattern.test(postText) || postText === '';
    const hasFiles = selectedFiles.length > 0 || selectedGif;
    const isValidPost = !isEmpty || hasFiles;

    if (socket && userDetail) {
      if (isValidPost || repostedId) {
        const sendData = {
          text: postText,
          files: selectedFiles,
          user: userDetail,
          replyToPostId,
          repostedId,
          quotedPostId,
          quoted,
          gifUrl: selectedGif,
          authorname,
          authoremail,
        };
        if (selectedFiles.length || selectedGif) {
          dispatch(
            fileLoadingAction({
              percent: 0,
              status: 'pending',
              totalFileSize: 0,
              uploadedMB: 0,
            })
          );
        }
        socket.emit(CREATE_POST, sendData);
        setPostText('');
        setSelectFiles([]);
        setSelectedGif(null);
      } else {
        toast.error('Post cannot be empty. Please add some content or upload files/GIF.');
      }
    } else {
      router.push('/login');
    }
  };

  return { posts, handleFileSelect, handleEditHeight, handlePostText, handleSendMessage, editorHeight, postText, selectedFiles, deleteSelectedFile, handleSelectGif, selectedGif, deleteSelectedGif, filesLoading, progress };
};

export default usePostSocket;
