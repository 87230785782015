import ArrowLeftIcon from '@/assets/icons/icon-active-back.svg';
import LeftIcon from '@/assets/icons/icon-left.svg';
import { cn } from '@/utils/helper';

interface RightSidebarMenuProps {
  links: {
    id: number;
    txt: string;
    handler?: () => void;
  }[];
  isShow: () => void;
}

const RightSidebarMenu: React.FC<RightSidebarMenuProps> = ({ links, isShow }) => {
  const mobileLinks = links.slice(0, 2);

  return (
    <article className='h-full !bg-transparent lg:dark:!bg-char-coal'>
      <div className={cn('relative flex w-full h-8 justify-center items-center !bg-transparent')}>
        <button onClick={isShow} type='button' className='flex justify-center items-center size-6 lg:size-5 absolute left-0'>
          <LeftIcon className='fill-white block lg:hidden' />
          <ArrowLeftIcon className='[&_path]:!fill-primary hidden lg:block' />
        </button>

        <h3 className='text-coal-250 dark:text-white text-base lg:text-lg font-medium'>Menu</h3>
      </div>

      <>
        <ul className='flex flex-col py-2 ss:py-5 gap-1 ss:gap-2 lg:hidden'>
          {links.map(({ id, txt, handler }) => {
            return (
              <li key={id}>
                <button className='text-coal-250 py-1.5 ss:py-3 px-6 dark:text-white text-sm ss:text-base/5 font-medium hover:!bg-light-purple dark:hover:!bg-coal-100 lg:dark:hover:!bg-jet-black w-full text-left rounded-[82px]' type='button' onClick={handler}>
                  {txt}
                </button>
              </li>
            );
          })}
        </ul>
        <ul className='lg:flex flex-col py-2 ss:py-5 gap-1 ss:gap-2 hidden'>
          {mobileLinks.map(({ id, txt, handler }) => {
            return (
              <li key={id}>
                <button className='text-coal-250 py-1.5 ss:py-3 px-6 dark:text-white text-sm ss:text-base/5 font-medium hover:!bg-light-purple dark:hover:!bg-coal-100 lg:dark:hover:!bg-jet-black w-full text-left rounded-[82px]' type='button' onClick={handler}>
                  {txt}
                </button>
              </li>
            );
          })}
        </ul>
      </>
    </article>
  );
};

export default RightSidebarMenu;
