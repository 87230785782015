import React from 'react';

import { useDisconnect, useAccount } from 'wagmi';

import { useAppDispatch } from '@/hooks/redux';
import { setShowAnimation } from '@/redux/auth/authSlice';
import { openWalletModal } from '@/redux/wallet/userSlice';
import { removeCookie } from '@/utils/cookies';

const ConnectionButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const { disconnect } = useDisconnect();
  const { isConnected, connector } = useAccount();

  const walletDisconnect = () => {
    disconnect({ connector });
    removeCookie('portfolio');
    import('../../../api/mixpanel').then((mod) => {
      mod.Mixpanel.track(`${connector?.name} - Disconnected`);
      mod.Mixpanel.reset();
    });
    dispatch(setShowAnimation(false));
  };
  return (
    <>
      {' '}
      {isConnected ? (
        <button onClick={() => walletDisconnect()} type='button' className='text-primary-blue-100 !bg-primary/15 py-3 px-6 text-sm ss:text-base/5 font-medium rounded-[4px] hover:opacity-75 active:scale-95'>
          Disconnect
        </button>
      ) : (
        <button
          onClick={() => {
            dispatch(openWalletModal(true));
          }}
          className='text-primary-blue-100 !bg-primary/15 py-3 px-6 text-sm ss:text-base/5 font-medium rounded-[4px] hover:opacity-75 active:scale-95'
        >
          Connect Wallet
        </button>
      )}
    </>
  );
};

export default ConnectionButton;
