import { useQuery } from '@tanstack/react-query';

import { getRequestBackend } from '@/api/loans';
import { GET_CONVERSION } from '@/utils/queryKey';

interface IUseConversionParams {
  symbol: string;
  convert: string[] | undefined;
}

export type TConversionData = {
  data: Array<{
    id: number;
    symbol: string;
    name: string;
    amount: number;
    last_updated: string;
    quote: {
      [key: string]: {
        price: number;
        last_updated: string;
      };
    };
  }>;
};

export const useConversion = ({ symbol, convert }: IUseConversionParams) => {
  return useQuery({
    queryKey: [GET_CONVERSION, symbol],
    queryFn: () => {
      return getRequestBackend('/crypto/conversions', {
        params: {
          symbol,
          convert: convert!.join(','),
        },
      }) as Promise<TConversionData>;
    },
    select: ({ data }) => data,
    enabled: !!convert,
  });
};
