import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/navigation';
import { toast } from 'react-hot-toast';
import { useAccount } from 'wagmi';

import { getUserDetail, passwordReset, updateUserDetail, uploadUserImg } from '@/api/api';
import ArrowLeftIcon from '@/assets/icons/icon-active-back.svg';
import LeftIcon from '@/assets/icons/icon-left.svg';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setUserImage } from '@/redux/wallet/userSlice';
import { cn, validateEmail, validateImage } from '@/utils/helper';

import UserImageUploader from './UserImageUploader';

type ProfileSettingsProps = {
  isShow: () => void;
};

const initialState = {
  name: '',
  email: '',
  profile_picture: '',
};
const ProfileSettings: React.FC<ProfileSettingsProps> = ({ isShow }) => {
  const [validateErr, setValidationErr] = useState(initialState);
  const [selectFile, setSelectFile] = useState<File | null>();
  const [initialValues, setInitialValues] = useState<null | typeof initialState>(null);
  const [values, setValues] = useState(initialState);
  const { userDetail } = useAppSelector((state) => state.user);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { address } = useAccount();

  const handleValidation = (name: string, errorMessage: string) => {
    setValidationErr((prev) => ({
      ...prev,
      [name]: errorMessage,
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let errorMessage = '';

    if (name === 'email' && !validateEmail(value)) {
      errorMessage = 'Email address should be valid';
    } else if (name === 'name' && value.length < 1) {
      errorMessage = 'Username cannot be empty';
    }

    handleValidation(name, errorMessage);
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fileSelect = (file: File) => {
    if (file) {
      const imageValidationErr = validateImage(file);
      if (imageValidationErr) {
        setSelectFile(null);
        setValidationErr((prev) => {
          return {
            ...prev,
            name: imageValidationErr,
          };
        });
      } else {
        setValidationErr((prev) => {
          return {
            ...prev,
            name: '',
          };
        });
        setSelectFile(file);
      }
    }
  };

  const postImage = async (image: File) => {
    const formData = new FormData();
    formData.append('profileImage', image);
    const res = await uploadUserImg(formData);
    return res;
  };

  const resetPass = async () => {
    toast.promise(passwordReset(), {
      loading: 'loading...',
      success: <b>Password reset send</b>,
      error: <b>Oops something went wrong</b>,
    });
  };

  const handleSubmit = async () => {
    if (Object.values(validateErr).some((item) => item)) return;
    toast.promise(
      updateUserDetail({
        name: values.name,
        email: values.email,
      }),
      {
        loading: 'Saving...',
        success: <b>Saved</b>,
        error: <b>Oops something went wrong</b>,
      }
    );

    if (selectFile) {
      const uploaded: { image_url: string } = await postImage(selectFile);
      if (uploaded) {
        setSelectFile(null);
        dispatch(setUserImage(uploaded.image_url));
      }
    }
    router.refresh();
  };

  useEffect(() => {
    const getUser = async () => {
      const res = await getUserDetail({ address: address ?? '' });
      setValues(res);
      setInitialValues(res);
    };
    getUser();
  }, [address]);

  return (
    <article className={cn('flex h-full flex-col gap-5 pt-5 lg:dark:!bg-char-coal')}>
      <div className={cn('relative flex w-full h-8 justify-center items-center !bg-transparent')}>
        <button onClick={isShow} type='button' className='flex justify-center items-center size-6 lg:size-5 absolute left-0'>
          <LeftIcon className='fill-white block lg:hidden' />
          <ArrowLeftIcon className='[&_path]:!fill-primary hidden lg:block' />
        </button>

        <h3 className='max-lg:hidden text-coal-250 dark:text-white text-base lg:text-lg font-medium'>Edit Profile</h3>
      </div>

      <div className='flex justify-between lg:justify-center items-start gap-2 !bg-transparent'>
        <UserImageUploader userDetail={userDetail} validateErr={validateErr} onFileSelect={fileSelect} />

        <button type='button' className='lg:hidden !text-primary text-sm font-normal hover:opacity-75 active:scale-95' onClick={resetPass}>
          Reset Password
        </button>
      </div>

      <div className='!bg-transparent flex-1 flex flex-col gap-6 lg:gap-4'>
        <div className='!bg-transparent flex flex-col gap-3 lg:gap-2'>
          <h3 className='text-coal-250 dark:text-white text-base/5 lg:text-sm font-medium'>User Name</h3>
          <div className='!bg-transparent'>
            <input type='text' name='name' value={values.name} onChange={handleChange} placeholder='User Name' className='border w-full py-1 ss:py-3 px-4 ss:px-5 rounded-[70px] lg:rounded !text-coal-200 border-coal-150 lg:dark:!bg-jet-black' />

            {validateErr.name && <span className='text-red-400'>{validateErr.name}</span>}
          </div>
        </div>
        <div className='!bg-transparent flex flex-col gap-3 lg:gap-2'>
          <h3 className='text-coal-250 dark:text-white text-base/5 lg:text-sm font-medium'>Email</h3>
          <div className='!bg-transparent'>
            <input name='email' value={values.email} onChange={handleChange} placeholder='youremail@gmail.com' className='border w-full py-2 ss:py-3 px-4 ss:px-5 rounded-[70px] lg:rounded !text-coal-200 border-coal-150 lg:dark:!bg-jet-black' />

            {validateErr.email && <span className='text-red-400'>{validateErr.email}</span>}
          </div>
        </div>
      </div>

      <div className='pb-5 !bg-transparent flex flex-col items-center gap-3'>
        <button type='button' className='max-lg:hidden !text-primary text-sm font-normal hover:opacity-75 active:scale-95' onClick={resetPass}>
          Reset Password
        </button>
        <button
          disabled={values.name === initialValues?.name && values.email === initialValues.email && !selectFile}
          type='button'
          className={`w-full flex items-center justify-center rounded-[82px] text-base/5 font-medium text-white hover:opacity-100 !bg-primary active:scale-95 py-2 ss:py-[11px] disabled:!bg-[#454545] disabled:opacity-50 ${Object.values(validateErr).some((item) => item) ? 'bg-off-blue' : 'bg-primary'}`}
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </article>
  );
};
export default ProfileSettings;
