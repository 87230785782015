import { cn } from '@/utils/helper';

import loaderIcon from '../assets/images/loader.gif';

const Loader: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={cn('m-auto inset-0 h-fit w-fit !bg-transparent', className)}>
      <img src={loaderIcon.src} alt='photo' className='w-16 h-16' />
    </div>
  );
};

export { Loader };
