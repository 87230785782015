export const CREATE_POST = 'create-post';
export const RECEIVE_POST = 'receive-post';
export const TOGGLE_POST_REACTION = 'toggle-reaction';
export const RECEIVE_POST_REACTION = 'receive-post-reaction';
export const SENT_POST = 'sent-post';
export const UNDO_REPOST = 'undo-repost';
export const DELETE_POST = 'delete-post';
export const DELETED_POST = 'deleted-post';
export const EDIT_POST = 'edit-post';
export const EDITED_POST = 'edited-post';
export const RECEIVE_UPLOAD_PROGRESS = 'upload-progress';
