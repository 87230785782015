import { ConnectWallet } from './ConnectWallet';
import WalletButtons from './WalletButtons';

interface IWalletModal {
  isModalShow: () => void;
}
const WalletModal: React.FC<IWalletModal> = ({ isModalShow }) => {
  return (
    <div className='px-5 lg:px-0 absolute backdrop-blur-[273px] top-0 left-0 right-0 z-[2000] h-full flex flex-col gap-[10px] justify-center items-center'>
      <ConnectWallet isModalshow={isModalShow}>
        <WalletButtons />
      </ConnectWallet>
    </div>
  );
};

export { WalletModal };
