import { useTheme } from '@/hooks/useTheme';

import CreatePost from './create-post';

const stylesContent = {
  direction: 'flex-col px-[34px] gap-[34px]',
  name: 'block',
  image: 'w-[45px] h-[45px]',
  postBtn: 'absolute top-[50px] rounded-[43px] right-[31px] w-[108px] flex items-center gap-[10px]',
  borderAttach: 'border-t pt-4 pr-[31px]',
};
const CreatePostModal = () => {
  const { mode } = useTheme();
  return (
    <div className='h-[calc(100dvh-86px)] pt-[27px] overflow-auto'>
      <CreatePost stylesContent={{ ...stylesContent, mode }} />
    </div>
  );
};

export default CreatePostModal;
