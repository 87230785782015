import { AxiosRequestConfig } from 'axios';

import { IActiveLoan, IMyLoan, ILoanRequest, ILoanRequestDetail, ILoanStatistics, TActiveLoans, TLoanSortParams, TLoanSortParamsWithAddress, ILoanCollectionWithPagination } from '@/redux/loans/model';
import { SIMPLEHASH_CHAIN } from 'constant';
import { getSimpleHash } from 'simplehash_api/walletApi';
import { AddressType } from 'types';

import { IAcceptLoanRequestPayload, ICollectionLoanDetails, ICollectionSimplehashDetails, ILoanRequestPayload, ILoanTermBaseFields, IPaybackLoanPayload, IPayloadUpdateLoanMember, IResponseCollection, IResponseNFT, ISignatureData, ISimplehashNFTDetails } from './model';
import { deleteRequest, getRequest, postQuicknode, postRequest, putRequest } from '../api';

export const postEscrow = async (signatureData: ISignatureData): Promise<string> => {
  const res = await postRequest('/contracts/escrow', signatureData, undefined, false, true);
  return res.signature;
};

const postRequestBackend = (url: string, body: any, config?: AxiosRequestConfig) => {
  return postRequest(url, body, config, false, true);
};

export const getRequestBackend = (url: string, config?: AxiosRequestConfig) => {
  return getRequest(url, config, false, true);
};

export const makeLoanOffer = async (loanOfferPayload: ILoanRequestPayload) => {
  return postRequestBackend('/loan/request', loanOfferPayload);
};

type SimplifiedData = Omit<IResponseNFT, 'next_cursor' | 'next' | 'previous'> & { nextCursors: string[] };
type fnReturnType<T> = T extends string | null ? IResponseNFT : SimplifiedData;
export const getNFTsInWallet = async <T extends string | { address: string }[] | null>(walletAddress: AddressType, next: string | null, contract_ids: T, order_by?: string, collectionIds?: string): Promise<fnReturnType<T>> => {
  if (typeof contract_ids === 'object' && contract_ids) {
    const chunks = [];

    for (let i = 0; i < contract_ids.length; i += 50) {
      chunks.push(contract_ids.slice(i, i + 50));
      // do whatever
    }

    const cols = await Promise.all(
      chunks.map((contractArr) => {
        return getSimpleHash<IResponseNFT>('owners_v2', {
          chains: SIMPLEHASH_CHAIN,
          wallet_addresses: walletAddress,
          contract_ids: contractArr.map(({ address }) => `${SIMPLEHASH_CHAIN}.${address}`).join(','),
          cursor: next,
          order_by: order_by || 'last_sale_price__desc',
          ...(collectionIds ? { collection_ids: collectionIds } : {}),
        });
      })
    );

    const data = cols.reduce<SimplifiedData>(
      (agg, nftData) => {
        const nextCursors = [...agg.nextCursors];

        if (nftData.next_cursor) {
          nextCursors.push(nftData.next_cursor);
        }
        const nfts = {
          nextCursors,
          nfts: [...agg.nfts, ...nftData.nfts],
        };
        return nfts;
      },
      { nfts: [], nextCursors: [] }
    );

    return data as fnReturnType<T>;
  }

  return getSimpleHash('owners_v2', {
    chains: SIMPLEHASH_CHAIN,
    wallet_addresses: walletAddress,
    ...(contract_ids && { contract_ids: `${SIMPLEHASH_CHAIN}.${contract_ids}` }),
    cursor: next,
    order_by: order_by || 'last_sale_price__desc',
    ...(collectionIds ? { collection_ids: collectionIds } : {}),
  });
};

export const getLoanCollectionsDetail = (contractAddress: string): Promise<IResponseCollection> => {
  return postQuicknode('', {
    id: 67,
    jsonrpc: '2.0',
    method: 'qn_fetchNFTCollectionDetails',
    params: [
      {
        page: 1,
        perPage: 80,
        contracts: [contractAddress],
      },
    ],
  });
};

export const getLoanCollections = <T = ILoanCollectionWithPagination>(params: TLoanSortParams | undefined): Promise<T> => {
  return getRequestBackend('/loan/collections/', { params });
};

export const getLoanRequests = (config?: AxiosRequestConfig): Promise<ILoanRequest[]> => {
  return getRequestBackend('/loan/request', config);
};

export const getLoanRequestDetails = (id: string, config?: AxiosRequestConfig): Promise<ILoanRequestDetail> => {
  return getRequestBackend(`/loan/request/${id}`, config);
};

export const getActiveLoans = (config?: AxiosRequestConfig): Promise<TActiveLoans> => {
  return getRequestBackend('/loan/active-loans', config);
};

export const getActiveLoan = (id: string, config?: AxiosRequestConfig): Promise<IActiveLoan> => {
  return getRequestBackend(`/loan/active-loans/${id}`, config);
};

export const acceptLoanRequestByBackend = (body: IAcceptLoanRequestPayload): Promise<ILoanRequest[]> => {
  return postRequestBackend('/loan/accept-request', body);
};

export const deleteLoanRequest = (id: string) => {
  return deleteRequest(`/loan/request/${id}`);
};

export const updateLoanRequest = (id: string, body: ILoanTermBaseFields) => {
  return putRequest(`/loan/request/${id}`, body);
};

export const repayLoanByBackend = async (body: IPaybackLoanPayload) => {
  await postRequestBackend('/loan/repay', body);
};

export const forecloseLoanByBackend = async (body: IPaybackLoanPayload) => {
  await postRequestBackend('/loan/foreclose', body);
};

export const getLoanStatistics = async ({ address }: { address: AddressType }): Promise<ILoanStatistics> => {
  return getRequestBackend('/loan/statistics', {
    params: {
      address,
    },
  });
};

export const getLentLoans = async (params: TLoanSortParamsWithAddress): Promise<IMyLoan[]> => {
  return getRequestBackend('/loan/lent', {
    params,
  });
};

export const getBorrowedLoans = async (params: TLoanSortParams & { address: string }): Promise<IMyLoan[]> => {
  return getRequestBackend('/loan/borrowed', {
    params,
  });
};

export const getOffersLoans = async (params: TLoanSortParamsWithAddress): Promise<ILoanRequest[]> => {
  return getRequestBackend('/loan/my-loan-offers', {
    params,
  });
};

export const cancelLoanOffer = async (id: number) => {
  return deleteRequest(`/loan/offer/${id}`);
};

export const searchCollections = async (searchTerm: string): Promise<ILoanCollectionWithPagination> => {
  return getRequestBackend('/loan/collections/', {
    params: {
      search: searchTerm,
    },
  });
};

export const getCollectionDetailsSimplehash = (collection_ids: string) => {
  return getSimpleHash<ICollectionSimplehashDetails>('collections/ids', {
    collection_ids,
  });
};

export const getCollectionDetailsByContract = (contractAddress: string) => {
  return getSimpleHash<ICollectionSimplehashDetails>(`collections/${SIMPLEHASH_CHAIN}/${contractAddress}`, {});
};

export const getLoanCollectionDetails = async (id: string, params: any): Promise<ICollectionLoanDetails> => {
  return getRequestBackend(`/loan/collections/${id}`, { params });
};

export const getNFTDetails = async (contract: string, tokenId: number | string): Promise<ISimplehashNFTDetails> => {
  return getSimpleHash(`${SIMPLEHASH_CHAIN}/${contract}/${tokenId}`);
};

export const updateLoanMemberAddress = async ({ id, address }: IPayloadUpdateLoanMember) => {
  return putRequest(`/loan/update-loan-member-address/${id}`, undefined, {
    params: {
      address,
    },
  });
};
