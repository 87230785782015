import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useSocket } from '@/components/providers/socket-provider';
import { setMessageNotification } from '@/redux/channel/channelSlice';
import { INVITE_NOTIFICATION } from 'socket/channel/constant';

import { useAppDispatch } from './redux';
import { useSoundMode } from './useSoundMode';

const useNotificationSocket = () => {
  const { soundMode } = useSoundMode();
  const { socket } = useSocket();
  const dispatch = useAppDispatch();
  const router = useRouter();
  useEffect(() => {
    const audioElement = new Audio('/audio/mixkit-message-pop-alert-2354.mp3');

    if (socket) {
      socket.on('notification', (data) => {
        if (soundMode === 'on') {
          audioElement
            .play()
            .then(() => {})
            .catch((error) => {
              console.error('Failed to play audio:', error);
            });
        }
        dispatch(setMessageNotification(data));
      });
      socket.on(INVITE_NOTIFICATION, (data) => {
        if ('Notification' in window && Notification.permission === 'granted') {
          const notification = new Notification('New Notification', {
            body: `You've been invited to join the ${data.channelName} by ${data.user}`,
            icon: '/purple-small.png',
          });
          notification.onclick = (event) => {
            event.preventDefault();
            router.push('/feed');
          };
        }
      });
    }
    return () => {
      socket?.off('notification');
      socket?.off(INVITE_NOTIFICATION);
    };
  }, [dispatch, router, socket, soundMode]);
};

export default useNotificationSocket;
