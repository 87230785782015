import { useEffect } from 'react';

import { useRouter } from 'next/router';

import useWalletAllNFTs from '@/hooks/query/useWalletAllNFTs';
import useWalletCryptos from '@/hooks/query/useWalletCrypto';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { fetchSubscribedChannels } from '@/redux/channel/channelSlice';
import { togglePanel } from '@/redux/panel/panelSlice';
import { USDDollarFormatter } from '@/utils/formatter';

import ProfileSettings from '../ProfileSettings';
import Notifications from './Notifications';
import RightSidebarAccountSettings from './RightSidebarAccountSettings';
import RightSidebarMenu from './RightSidebarMenu';

interface IDetailsPanelProps {
  setInnerTab: (tab: 'Account' | 'Notifications' | 'Menu' | null) => void;
  innerTab: 'Account' | 'Notifications' | 'Menu' | null;
}

const DetailsPanel: React.FC<IDetailsPanelProps> = ({ setInnerTab, innerTab }) => {
  const router = useRouter();
  const { userDetail } = useAppSelector((state) => state.user);
  const dispatchApp = useAppDispatch();
  const { data: walletValuation } = useWalletAllNFTs();
  const { data: walletCrypto } = useWalletCryptos();
  const nettyWorth = walletCrypto?.walletCryptoBalance && walletValuation ? walletCrypto.walletCryptoBalance + walletValuation : 0;

  useEffect(() => {
    dispatchApp(fetchSubscribedChannels({}));
  }, [dispatchApp]);

  const panels = [
    {
      id: 1,
      txt: 'NettyWorth',
      value: USDDollarFormatter(nettyWorth),
      preFix: true,
    },
    {
      id: 2,
      txt: 'NettyScore',
      unit: '%',
      value: userDetail?.nettyScore ?? 0,
      postFix: true,
    },
    {
      id: 3,
      txt: 'Active Loans',
      value: userDetail?.activeCount ?? 0,
    },
    {
      id: 4,
      txt: 'Loan Offers',
      value: userDetail?.loanOffers ?? 0,
    },
    {
      id: 5,
      txt: 'Lending',
      value: userDetail?.lendingCount ?? 0,
    },
  ];

  const menuLinks = [
    {
      id: 1,
      txt: 'Notifications',
      handler: () => setInnerTab('Notifications'),
    },
    {
      id: 2,
      txt: 'Edit Profile',
      handler: () => setInnerTab('Account'),
    },
    {
      id: 3,
      txt: 'Contact Us',
      handler: () => {
        router.push('/contact');
        dispatchApp(togglePanel(null));
      },
    },
    {
      id: 4,
      txt: 'Request a Feature',
      handler: () => {
        router.push('/feedback');
        dispatchApp(togglePanel(null));
      },
    },
    {
      id: 5,
      txt: 'API/SDK',
      handler: () => {
        router.push('/api-sdk');
        dispatchApp(togglePanel(null));
      },
    },
    {
      id: 6,
      txt: 'Docs',
      handler: () => {
        if (typeof window !== 'undefined') {
          window.open('https://docs.nettyworth.io/docs/nettyworth', '_blank');
          dispatchApp(togglePanel(null));
        }
      },
    },
    {
      id: 7,
      txt: 'Privacy Policy',
      handler: () => {
        router.push('/privacy');
        dispatchApp(togglePanel(null));
      },
    },
    {
      id: 8,
      txt: 'Terms of Service',
      handler: () => {
        router.push('/service');
        dispatchApp(togglePanel(null));
      },
    },
  ];

  if (innerTab && innerTab === 'Menu') {
    return <RightSidebarMenu links={menuLinks} isShow={() => setInnerTab(null)} />;
  }

  if (innerTab && innerTab === 'Account') {
    return <ProfileSettings isShow={() => setInnerTab('Menu')} />;
  }

  if (innerTab && innerTab === 'Notifications') {
    return <Notifications isShow={() => setInnerTab('Menu')} />;
  }

  return <RightSidebarAccountSettings panels={panels} setInnerTab={setInnerTab} />;
};

export default DetailsPanel;
