import { useCallback } from 'react';

import { useConversion } from '@/hooks/query/crypto/useConversion';
import { useLoanTokens } from '@/hooks/query/loans/useLoanTokens';
import { ICollectionDetail } from 'simplehash_api/types/simpleHash';

import { convertWeiToEthers } from './convertValueToWei';

export const getFloorPrice = (floorPrices: Pick<ICollectionDetail['floor_prices'][number], 'marketplace_id' | 'value'>[] | undefined, withWei = false, onlyOpensea = false) => {
  const floorPrice = floorPrices?.length ? floorPrices?.find(({ marketplace_id }) => marketplace_id === 'opensea')?.value || (!onlyOpensea ? floorPrices[0].value : null) : null;
  return floorPrice ? (withWei ? floorPrice : convertWeiToEthers(floorPrice)) : null;
};

export const useCalculatePrincipal = () => {
  const { data: tokens } = useLoanTokens();
  const { data: conversions } = useConversion({ symbol: 'ETH', convert: tokens?.filter(({ symbol }) => symbol !== 'NW').map(({ symbol }) => symbol) });

  return useCallback((ltv: number, convertedFloorPrice: number, currency: string) => (convertedFloorPrice && conversions ? Number((ltv * (conversions[0].quote[currency]?.price || 1) * convertedFloorPrice).toFixed(2)) / 100 : 0), [conversions]);
};

export const useCalculateLtv = () => {
  const { data: tokens } = useLoanTokens();
  const { data: conversions } = useConversion({ symbol: 'ETH', convert: tokens?.filter(({ symbol }) => symbol !== 'NW').map(({ symbol }) => symbol) });

  return useCallback((principal: number, convertedFloorPrice: number, currency: string) => (convertedFloorPrice ? (((principal / (conversions?.[0].quote[currency]?.price || 1)) * 100) / convertedFloorPrice).toFixed(2) : 0), [conversions]);
};

export const useDisplayLTV = () => {
  const calculateLtv = useCalculateLtv();
  return useCallback((principal: number | null, floorPrice: number | null | undefined, currency: string | undefined) => (floorPrice && principal && currency ? `${calculateLtv(principal, convertWeiToEthers(floorPrice), currency)}%` : 'N/A'), [calculateLtv]);
};
