export function numberWithCommas(x: string | number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export const truncateAddress = (address: any) => {
  if (!address) return 'No Account';
  const match = address.match(/^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};
export const kFormatter = (num: number, options?: Intl.NumberFormatOptions) => {
  const formatter = new Intl.NumberFormat('en', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumSignificantDigits: 2,
    ...options,
  });

  return formatter.format(num);
};

export const centToUsdFormatter = (cent: number) => {
  const dollar = cent / 100;
  return dollar;
};

export const hexToETh = (num: any) => {
  return parseInt(num, 10) / Math.pow(10, 18);
};
export function youtubeParser(url: string) {
  const youtubeLinkRegex = /https:\/\/www\.youtube\.com\/watch\?v=[A-z0-9_-]+/g;
  const shortLink = /https:\/\/youtu\.be\/[A-Za-z0-9_-]+/g;
  const youtubeLink = url?.match(youtubeLinkRegex) || url?.match(shortLink);
  return youtubeLink ? youtubeLink[0] : '';
}

export const formatCash = (n: number) => {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(n);
};

export const convertIpfsLink = (ipfsLink: string) => {
  const hash = ipfsLink.replace('ipfs://', '');
  return `https://ipfs.io/ipfs/${hash}`;
};

export const USDDollarFormatter = (value: number, sign: string = 'USD', options?: Intl.NumberFormatOptions) => {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: sign,
    ...options,
  });
  return USDollar.format(value);
};

export const buildParams = <T extends Record<string, any>>(data: T) => {
  const params = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    if (value !== null) {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          if (val !== null) {
            params.append(key, val.toString());
          }
        });
      } else {
        params.append(key, value.toString());
      }
    }
  });

  return params;
};

const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

const triplet = (e1: number, e2: number, e3: number) => keyStr.charAt(e1 >> 2) + keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) + keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) + keyStr.charAt(e3 & 63);

export const rgbDataURL = (r: number, g: number, b: number) => `data:image/gif;base64,R0lGODlhAQABAPAA${triplet(0, r, g) + triplet(b, 255, 255)}/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;

export const formatImgUrl = (url: string) => {
  return url.replace(/-600x400/i, '');
};

export const truncateStringWithDots = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str;
  }

  const ellipsis = '...';
  const ellipsisLength = ellipsis.length;

  const breakPoint = Math.floor((maxLength - ellipsisLength) / 2);

  const truncatedString = str.slice(0, breakPoint) + ellipsis + str.slice(str.length - breakPoint);

  return truncatedString;
};

export const capitalizeLongWords = (channelName: string) => {
  const exceptions = ['a', 'an', 'the', 'and', 'but', 'or', 'for', 'nor', 'on', 'at', 'to', 'from', 'by', 'as', 'in', 'of'];
  const words = channelName.toLowerCase().split(' ');

  for (let i = 0; i < words.length; i++) {
    if (!exceptions.includes(words[i]) || i === 0) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
  }

  return words.join(' ');
};

export const WEIToETH = (value: number, decimals: number) => {
  return value / Math.pow(10, decimals);
};

export const trailingZeroCount = (num: number) => {
  const str = num.toString();
  const foundZeroMatch = str.match(/(\.0*)/);
  const zeroCount = foundZeroMatch ? foundZeroMatch[0].length - 1 : 0;
  return zeroCount;
};

export const displayRepay = (repay: string | number) => Number.parseFloat(Number(repay).toFixed(3));

export const customFixed = (num: number, decimalPlaces = 4, typeNumber = true) => {
  let fixedNum = num.toFixed(decimalPlaces);
  while (Number(fixedNum) === 0 && decimalPlaces < 20) {
    decimalPlaces++;
    fixedNum = num.toFixed(decimalPlaces);
  }
  return typeNumber ? Number(fixedNum) : fixedNum;
};

export type ParamsType<T> = T extends (...args: infer P) => any ? P[0] : never;
