import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { cancelLoanOffer, getActiveLoan, getActiveLoans, getBorrowedLoans, getLentLoans, getLoanCollections, getLoanCollectionsDetail, getLoanRequestDetails, getLoanRequests, getLoanStatistics, getNFTsInWallet, getOffersLoans, updateLoanRequest } from '@/api/loans';
import { ILoanTermBaseFields } from '@/api/loans/model';

import { LoansState, ILoanRequest, ILoanNFT, ILoanCollection, TActiveLoans, IActiveLoan, ILoanRequestDetail, ILoanStatistics, IMyLoan, INFTsPagination, IFetchNFTs, ILoanCollectionQuicknodeDetail, TLoanSortParams, TLoanSortParamsWithAddress, ILoanCollectionWithPagination } from './model';
import type { RootState } from '../store';
import { AddressType } from 'types';

export const myLoanRequests = createAsyncThunk<ILoanRequest[], string | string[] | undefined>('loans/my', async (searchTerm, { rejectWithValue }) => {
  try {
    const res = await getLoanRequests({
      params: {
        searchTerm,
      },
    });
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchNFTs = createAsyncThunk<INFTsPagination & { spread: boolean }, IFetchNFTs, { state: RootState }>('loans/fetchNFTs', async ({ walletAddress, order_by, searchTerm }, { rejectWithValue, dispatch, getState }) => {
  try {
    const {
      loans: { NFTsPagination, myLoanRequests: stateMyLoanRequest },
    } = getState();

    const pagination = [...NFTsPagination.next];

    const next = NFTsPagination?.next.length ? pagination.shift() : null;

    const prevOrderBy = NFTsPagination?.order_by;
    const collections = await getLoanCollections<ILoanCollection[]>({
      select: 'address',
    });
    const res = await getNFTsInWallet(walletAddress, prevOrderBy === order_by ? next || null : null, collections, order_by);

    let myRequestLoans = stateMyLoanRequest;
    myRequestLoans = await dispatch(myLoanRequests(searchTerm)).unwrap();

    const NFTPaginationLength = NFTsPagination ? NFTsPagination.NFTs.length + 1 : null;
    const data = res.nfts.reduce((agg: ILoanNFT[], asset) => {
      const collectionTokenId = parseInt(asset.token_id, 10);
      const isInMyLoans = !!myRequestLoans.find(({ tokenId }: any) => {
        return tokenId === collectionTokenId;
      });

      const idxNum = agg.length + 1;
      const uniqueId = NFTPaginationLength ? NFTPaginationLength + idxNum : idxNum;
      if (!isInMyLoans) {
        agg.push({ ...asset, id: collectionTokenId, uniqueId });
      }

      return agg;
    }, []);

    // const nextCursor = 'nextCursors' in res ? res.nextCursors : [res.next_cursor];

    return { NFTs: data, next: res.nextCursors, spread: !!next && prevOrderBy === order_by, order_by };
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchLendingOffers = createAsyncThunk<ILoanRequest[], TLoanSortParams | undefined | {}>('loans/offers', async (params, { rejectWithValue }) => {
  try {
    const res = await getLoanRequests({
      params: {
        ...params,
        withFloorPrice: true,
      },
    });

    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchLoanRequest = createAsyncThunk<ILoanRequestDetail, string>('loans/loan', async (id, { rejectWithValue }) => {
  try {
    const res = await getLoanRequestDetails(id);

    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchOpenLoanOffers = createAsyncThunk<ILoanRequest[], string>('loans/loan-open-offers', async (id, { rejectWithValue }) => {
  try {
    const res = await getLoanRequests({
      params: {
        tokenId: id,
        dontIncludeOwner: true,
      },
    });

    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchActiveLoans = createAsyncThunk<TActiveLoans, TLoanSortParams | undefined | {}>('loans/active-loans', async (params, { rejectWithValue }) => {
  try {
    const res = await getActiveLoans({
      params,
    });
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchActiveLoan = createAsyncThunk<IActiveLoan, { id: string }>('loans/active-loan', async ({ id }, { rejectWithValue }) => {
  try {
    const res = await getActiveLoan(id);
    return res;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchLoanCollections = createAsyncThunk<{ data: ILoanCollectionWithPagination; params: TLoanSortParams | {} | undefined }, TLoanSortParams | {} | undefined>('loans/collections', async (params, { rejectWithValue }) => {
  try {
    const data = await getLoanCollections(params);
    return { data, params };
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchLoanStatistics = createAsyncThunk<ILoanStatistics, { address: AddressType }>('loans/statistics', async ({ address }, { rejectWithValue }) => {
  try {
    const data = await getLoanStatistics({ address });
    return data;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchLentLoans = createAsyncThunk<IMyLoan[], TLoanSortParamsWithAddress>('loans/lent', async (params, { rejectWithValue }) => {
  try {
    const data = await getLentLoans(params);
    return data;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchBorrowedLoans = createAsyncThunk<IMyLoan[], TLoanSortParamsWithAddress>('loans/borrowed', async (params, { rejectWithValue }) => {
  try {
    const data = await getBorrowedLoans(params);
    return data;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchOffersLoans = createAsyncThunk<ILoanRequest[], TLoanSortParamsWithAddress>('loans/oferrs', async (params: TLoanSortParamsWithAddress, { rejectWithValue }) => {
  try {
    const data = await getOffersLoans(params);
    return data;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const deleteLoanOffer = createAsyncThunk<number, number>('loans/oferrs-delete', async (id: number, { rejectWithValue }) => {
  try {
    await cancelLoanOffer(id);
    return id;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchLoanCollectionsDetails = createAsyncThunk<ILoanCollectionQuicknodeDetail, string>('loans/collections-details', async (collectionAddress, { rejectWithValue }) => {
  try {
    const data = await getLoanCollectionsDetail(collectionAddress);

    return data.result[0];
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const putUpdateLoanRequest = createAsyncThunk<ILoanTermBaseFields, ILoanTermBaseFields & { id: string }>('loans/request-update', async ({ id, ...baseFields }, { rejectWithValue }) => {
  try {
    await updateLoanRequest(id, baseFields);

    return baseFields;
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

const initialState: LoansState = {
  loading: false,
  lendingLoading: false,
  NFTsPagination: { NFTs: [], next: [], order_by: '' },
  requestedLoan: null,
  collectionLoan: '',
  myLoanRequests: [],
  lendingOffers: [],
  loan: null,
  openOffers: [],
  activeLoans: [],
  activeLoansLoading: false,
  loanLoading: false,
  collections: {
    collectionOffers: [],
    total: 0,
  },
  collectionsLoading: false,
  activeLoan: null,
  activeLoanLoading: false,
  loanStatistics: null,
  loanStatisticsLoading: false,
  lentLoans: null,
  lentLoansLoading: false,
  borrowedLoansLoading: false,
  borrowedLoans: null,
  offersLoansLoading: false,
  offersLoans: null,
  collectionDetails: null,
  collectionDetailsLoading: false,
  loanCardView: 'card',
};

const loansSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {
    changeReqLoans: (state, action: PayloadAction<ILoanNFT | null>) => {
      state.requestedLoan = action.payload;
    },
    changeCollectionLoan: (state, action) => {
      state.collectionLoan = action.payload;
    },
    deleteNFTsFromBorrower: (state) => {
      if (!state.NFTsPagination?.NFTs || !state.requestedLoan) return;
      const NFTIndex = state.NFTsPagination.NFTs.findIndex(({ id: NFTId }) => NFTId === state.requestedLoan?.id);

      state.NFTsPagination.NFTs.splice(NFTIndex, 1);
    },
    setLoanDefaulted: (state, action) => {
      if (!state.lentLoans) return;

      const foundLoan = state.lentLoans.find(({ loanChainId }) => {
        return loanChainId === action.payload;
      });
      if (!foundLoan) return;
      foundLoan.status = 'foreclosed';
    },
    changeCardView: (state, action) => {
      state.loanCardView = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNFTs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNFTs.fulfilled, (state, action) => {
        state.loading = false;
        const payloadNfts = action.payload.NFTs;
        const stateNfts = state.NFTsPagination?.NFTs;
        state.NFTsPagination = {
          next: [...state.NFTsPagination.next.slice(1), ...action.payload.next],
          NFTs: action.payload.spread ? (stateNfts ? [...stateNfts, ...payloadNfts] : [...payloadNfts]) : payloadNfts,
          order_by: action.payload.order_by,
        };
      })
      .addCase(myLoanRequests.pending, (state) => {
        state.loading = true;
      })
      .addCase(myLoanRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.myLoanRequests = action.payload;
      })
      .addCase(fetchLendingOffers.pending, (state) => {
        state.lendingLoading = true;
      })
      .addCase(fetchLendingOffers.fulfilled, (state, action) => {
        state.lendingLoading = false;
        state.lendingOffers = action.payload;
      })
      .addCase(fetchLoanRequest.pending, (state) => {
        state.loan = null;
        state.loanLoading = true;
      })
      .addCase(fetchLoanRequest.fulfilled, (state, action) => {
        state.loan = action.payload;
        state.loanLoading = false;
      })
      .addCase(fetchLoanRequest.rejected, (state) => {
        state.loanLoading = false;
      })
      .addCase(fetchOpenLoanOffers.fulfilled, (state, action) => {
        state.openOffers = action.payload;
      })
      .addCase(fetchActiveLoans.fulfilled, (state, action) => {
        state.activeLoansLoading = false;
        state.activeLoans = action.payload;
      })
      .addCase(fetchActiveLoans.pending, (state) => {
        state.activeLoansLoading = true;
      })
      .addCase(fetchLoanCollections.pending, (state) => {
        state.collectionsLoading = true;
      })
      .addCase(fetchLoanCollections.fulfilled, (state, action) => {
        state.collectionsLoading = false;
        state.collections.total = action.payload.data.total;

        state.collections.collectionOffers = action.payload.params && 'page' in action.payload.params ? [...state.collections.collectionOffers, ...action.payload.data.collectionOffers] : action.payload.data.collectionOffers;
      })
      .addCase(fetchActiveLoan.pending, (state) => {
        state.activeLoanLoading = true;
      })
      .addCase(fetchActiveLoan.fulfilled, (state, action) => {
        state.activeLoanLoading = false;
        state.activeLoan = action.payload;
      })
      .addCase(fetchLoanStatistics.pending, (state) => {
        state.loanStatistics = null;
        state.loanStatisticsLoading = true;
      })
      .addCase(fetchLoanStatistics.fulfilled, (state, action) => {
        state.loanStatistics = action.payload;
        state.loanStatisticsLoading = false;
      })
      .addCase(fetchLentLoans.pending, (state) => {
        state.lentLoansLoading = true;
      })
      .addCase(fetchLentLoans.fulfilled, (state, action) => {
        state.lentLoans = action.payload;
        state.lentLoansLoading = false;
      })
      .addCase(fetchBorrowedLoans.pending, (state) => {
        state.borrowedLoansLoading = true;
      })
      .addCase(fetchBorrowedLoans.fulfilled, (state, action) => {
        state.borrowedLoans = action.payload;
        state.borrowedLoansLoading = false;
      })
      .addCase(fetchOffersLoans.pending, (state) => {
        state.offersLoansLoading = true;
      })
      .addCase(fetchOffersLoans.fulfilled, (state, action) => {
        state.offersLoans = action.payload;
        state.offersLoansLoading = false;
      })
      .addCase(fetchLoanCollectionsDetails.pending, (state) => {
        state.collectionDetailsLoading = true;
      })
      .addCase(fetchLoanCollectionsDetails.fulfilled, (state, action) => {
        state.collectionDetailsLoading = false;
        state.collectionDetails = action.payload;
      })
      .addCase(deleteLoanOffer.fulfilled, (state, action) => {
        if (state.offersLoans) {
          const openOffers = [...state.offersLoans];
          const newOpenOffers = openOffers.filter(({ id }) => id !== action.payload);
          state.offersLoans = newOpenOffers;
        }

        if (state.loan?.openOffers) {
          const openLoanDetailsOffers = [...state.loan.openOffers];

          openLoanDetailsOffers.splice(
            openLoanDetailsOffers.findIndex(({ id }) => id === action.payload),
            1
          );

          state.loan.openOffers = openLoanDetailsOffers;
        }
      })
      .addCase(putUpdateLoanRequest.fulfilled, (state, action) => {
        if (!state.loan) return;
        const { ownerRequest } = state.loan;
        state.loan.ownerRequest = {
          ...ownerRequest,
          ...action.payload,
          repay: action.payload.repay.toString(),
        };
      });
  },
});

export const { changeReqLoans, changeCollectionLoan, deleteNFTsFromBorrower, setLoanDefaulted, changeCardView } = loansSlice.actions;
export const selectRequestedLoan = (state: RootState) => state.loans.requestedLoan;

export default loansSlice.reducer;
