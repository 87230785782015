import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import { AxiosError } from 'axios';
import router from 'next/router';

import { setIsAuthenticated, setShowAnimation } from '@/redux/auth/authSlice';
import { togglePanel } from '@/redux/panel/panelSlice';
import { clearUserData } from '@/redux/wallet/userSlice';
import { removeCookie } from '@/utils/cookies';

import { useAppDispatch } from './redux';

const useLogout = () => {
  const dispatchApp = useAppDispatch();
  const { handleLogOut: dynamicLogout } = useDynamicContext();

  const handleLogout = async () => {
    try {
      removeCookie('jwt');
      removeCookie('_token');
      removeCookie('portfolio');
      localStorage.removeItem('dynamic_authentication_token');
      dynamicLogout();
      dispatchApp(setIsAuthenticated(false));
      dispatchApp(setShowAnimation(false));
      dispatchApp(clearUserData());
      router.push('/');
      dispatchApp(togglePanel(null));
      return true;
    } catch (e) {
      return (e as AxiosError).message;
    }
  };

  return handleLogout;
};

export default useLogout;
