import { useState } from 'react';

import usePopularWallets from '@/hooks/useWalletButtons';

interface IPopularConnectorProps {
  onClick: (connectorName: string) => Promise<void> | void;
  disableLoadingFunctionality?: boolean;
}

const PopularConenctors = ({ onClick, disableLoadingFunctionality }: IPopularConnectorProps) => {
  const [loadingConnector, setLoadingConnector] = useState<string | null>(null);
  const popularConnectors = usePopularWallets();

  return popularConnectors.map((connector) => (
    <button
      key={connector.id}
      className={`flex pl-4 items-center gap-[13px] rounded bg-white dark:bg-jet-black min-w-[239px] py-2 w-full dark:border ${loadingConnector === connector.name ? 'opacity-50 cursor-not-allowed' : ''}`}
      onClick={() => {
        const returned = onClick(connector.name);
        if (typeof returned !== 'undefined') {
          returned.finally(() => {
            setLoadingConnector(null);
          });
        }
        if (!disableLoadingFunctionality) {
          setLoadingConnector(connector.name);
        }
      }}
      disabled={loadingConnector === connector.name}
    >
      <img alt={connector.name} src={connector.image || '/placeholder-wallet.png'} className='min-w-[39px] size-[39px] rounded-full' />
      <p className='text-lg/6 font-semibold text-jet-black'>{connector.name}</p>
    </button>
  ));
};

export { PopularConenctors };
