import { useAccount } from 'wagmi';

const useConnectorLogo = () => {
  const { connector } = useAccount();
  const connectorsLogo: Record<string, string> = {
    MetaMask: '/metamask.png',
    'Coinbase Wallet': '/coinbase.svg',
    Phantom: '/phantom.png',
  };
  const activeConnectorLogo = connector?.name ? connectorsLogo[connector.name] : '';

  return { activeConnectorLogo };
};

export default useConnectorLogo;
