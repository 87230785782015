import moment from 'moment';

import AdvertiseIconLarge from '@/assets/icons/icon-advertise-lg.svg';
import BookmarkIcon from '@/assets/icons/icon-bookmark-purple.svg';
import CalendarIcon from '@/assets/icons/icon-calendar.svg';
import CommunitiesIcon from '@/assets/icons/icon-communities.svg';
import ContactIcon from '@/assets/icons/icon-contact.svg';
import PortfolioIcon from '@/assets/icons/icon-graph.svg';
import LoftyIcon from '@/assets/icons/icon-lofty.svg';
import MarketIcon from '@/assets/icons/icon-market.svg';
import NFTSalesIcon from '@/assets/icons/icon-nft-sales.svg';
import NewsPageIcon from '@/assets/icons/icon-paper.svg';
import SuggestIcon from '@/assets/icons/icon-suggest.svg';
import LoanIcon from '@/assets/icons/icon-dolar.svg';
import WalletIcon from '@/assets/icons/icon-wallet-small.svg';

export const intervals = [
  JSON.parse(
    JSON.stringify({
      title: '1D',
      val: moment().subtract(1, 'day'),
      granularity: 'hourly',
      to: moment().unix(),
      from: moment().subtract(1, 'day').unix(),
    })
  ),
  {
    title: '1W',
    val: moment().subtract(1, 'week'),
    granularity: 'daily',
    to: moment().format('YYYY-MM-DD'),
    from: moment().subtract('1', 'month').format('YYYY-MM-DD'),
  },
  {
    title: '1M',
    val: moment().subtract('1', 'month'),
    granularity: 'daily',
    to: moment().format('YYYY-MM-DD'),
    from: moment().subtract('1', 'month').format('YYYY-MM-DD'),
  },
  {
    title: 'All',
    val: moment().subtract('1', 'month'),
    granularity: 'daily',
    to: moment().format('YYYY-MM-DD'),
    from: moment().subtract('1', 'month').format('YYYY-MM-DD'),
  },
];

export const pagesHeader = [
  {
    label: 'Loans',
    path: '/loans',
    icon: LoanIcon,
  },
  {
    label: 'NettyWorth',
    path: '/',
    icon: () => null,
  },
  {
    label: 'Crypto Market',
    path: '/crypto-prices',
    icon: MarketIcon,
  },
  {
    label: 'Communities',
    path: '/communities',
    icon: CommunitiesIcon,
  },
  {
    label: 'NFT Sales',
    path: '/nft-top-sales',
    icon: NFTSalesIcon,
  },
  {
    label: 'Portfolio',
    path: '/portfolio',
    icon: PortfolioIcon,
  },
  {
    label: 'Netty News',
    path: '/crypto-news',
    icon: NewsPageIcon,
  },
  {
    label: 'Upcoming Drops',
    path: '/upcoming-Ethereum-nft-launches',
    icon: CalendarIcon,
  },
  {
    label: 'Upcoming Cardano NFT Projects',
    path: '/upcoming-Cardano-nft-launches',
    icon: CalendarIcon,
  },
  {
    label: 'Upcoming Solana NFT Projects',
    path: '/upcoming-Solana-nft-launches',
    icon: CalendarIcon,
  },
  {
    label: 'Upcoming All Blockchains NFT Projects',
    path: '/upcoming-nft-launches',
    icon: CalendarIcon,
  },
  {
    label: 'Bookmarks',
    path: '/bookmarks',
    icon: BookmarkIcon,
  },
  {
    label: 'My Wallet',
    path: '/portfolio/nfts',
    icon: WalletIcon,
  },
  {
    label: 'Advertise',
    path: '/nft-advertising',
    icon: AdvertiseIconLarge,
  },
  {
    label: ['About', 'NettyWorth'],
    path: '/about',
    icon: LoftyIcon,
  },
  {
    label: 'Suggest',
    path: '/feedback',
    icon: SuggestIcon,
  },
  {
    label: 'Contact Us',
    path: '/contact',
    icon: ContactIcon,
  },
];
