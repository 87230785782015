export const GET_QUOTE_HISTORICAL = 'quoteHistorical';
export const GET_QUOTE_LATEST = 'quoteLatest';
export const GET_WALLET_VALUATION = 'walletValuation';
export const GET_WALLET_CRYPTO = 'walletCryptos';
export const GET_WALLET_COLLECTION = 'walletCollection';
export const GET_WALLET_NFTs = 'walletNFTs';
export const GET_WALLET_ALL_NFTs = 'walletAllNFTs';
export const GET_AVAILABE_CHAINs = 'availableChains';
export const GET_CURRENCY = 'currency';
export const GET_GASPRICE = 'gasPrice';
export const GET_ERC20_BALANCE = 'erc20Balance';
export const ETH_TO_USD_RATE = 'ethToUsdRate';
export const NFTS_BY_COLLECTION = 'NFTsByCollection';
export const COLLECTION_SEARCH = 'collectionSearch';
export const COLLECTION_DETAILS = 'collectionDetails';
export const COLLECTION_BY_CONTRACT = 'collection-by-contract';
export const GET_CONVERSION = 'get-conversion';
