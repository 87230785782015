import { useAppSelector } from './redux';

const useFeedProfile = () => {
  const { userDetail, userDetailLoading } = useAppSelector((state) => state.user);
  const { profile, profileLoading } = useAppSelector((state) => state.profile);

  const isMyProfile = !userDetailLoading && profileLoading === 'success' ? profile!.id === userDetail?.id : false;
  const currentUser = isMyProfile ? userDetail : profile;
  const isLoading = userDetailLoading || profileLoading === 'pending';

  return {
    isMyProfile,
    currentUser,
    isLoading,
    userDetail,
    profile,
  };
};

export default useFeedProfile;
