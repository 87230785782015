import { useQuery } from '@tanstack/react-query';

import { getGasPrice } from '@/api/api';
import { GET_GASPRICE } from '@/utils/queryKey';

const useGasPrice = () => {
  const query = useQuery({
    queryKey: [GET_GASPRICE],
    queryFn: () => getGasPrice(),
  });

  return query;
};

export default useGasPrice;
