import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { AlertCategory } from '@/redux/alerts/model';
import { CryptoCurrencyParams, CryptoCurrencyStatParams, ICryptoListings, IData, IQuoteLatest, IQuoteLatestParams, ISymbol } from '@/redux/crypto/model';
import { FetchCryptoNewsParams, FetchNewsParams } from '@/redux/news/model';
import { TopSalesDetailNftsParams } from '@/redux/top-sales/model';
import { IEthCurrency, ISaveWallet, IUserDetails, UserDetailRes } from '@/redux/wallet/model';
import { clearCookies, getCookie } from '@/utils/cookies';
import { FavoriteType } from 'types';
import Router from 'next/router';

const BASE_URL = 'https://app-api-1.nettyart.io';
export const FILE_BASE_URL = 'https://s3.us-east-1.amazonaws.com/profile.nettyworth.io';
const NETTY_URL = process.env.NEXT_PUBLIC_NETTYWORTH_API;
const OPENSEA_URL = 'https://api.opensea.io/api/v1';
export const HYGRAPH_URL = `https://api-us-east-1.hygraph.com/v2/${process.env.NEXT_PUBLIC_HYGRAPH_API_KEY}/master`;
export const QUICKNODE_URL = 'https://crimson-tiniest-glitter.ethereum-sepolia.quiknode.pro/1c67a2a27e9f015073420174d217178f6791b37d';
export const ETHWallet = '0x04a6Fa2a0DdAA58186Bdb5A92627419eABa79635';
export const MAIL_CHIMP_URL = 'https://nettyart.us5.list-manage.com/subscribe/post-json?u=231e2a6d374606653a3e22abe&id=0d65199a02&f_id=0062efe6f0&c=jQuery19005687377112970748_1667558282051&amp=&subscribe=Subscribe&_=1667558282052';

export const getEthCurrency = async (): Promise<IEthCurrency> => {
  return axios.get(`https://api.etherscan.io/api?module=stats&action=ethprice&apikey=${process.env.NEXT_PUBLIC_ETHERSCAN_API_KEY}`).then((res) => res.data.result);
};
export const getGasPrice = async () => {
  return axios.get(`https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=${process.env.NEXT_PUBLIC_ETHERSCAN_API_KEY}`).then((res) => res.data.result);
};
export const getRequest = async (url: string, config?: AxiosRequestConfig, isOpenseaUrl: boolean = false, isNettyWorth = false, isQuicknode = false) => {
  let requestUrl;

  if (isNettyWorth) {
    requestUrl = NETTY_URL + url;
  } else if (isOpenseaUrl) {
    requestUrl = OPENSEA_URL + url;
  } else if (isQuicknode) {
    requestUrl = QUICKNODE_URL + url;
  } else {
    requestUrl = BASE_URL + url;
  }

  const requestConfig = { ...config };

  if (isOpenseaUrl) {
    requestConfig.headers = { accept: 'application/json', 'X-API-KEY': 'c1051ef9ad3643a0abaeb5f2a7126352' };
  } else {
    const token = getCookie('_token');
    if (token) {
      requestConfig.headers = {
        ...requestConfig?.headers,
        Authorization: `Bearer ${token}`,
        // withCredentials: true
      };
    }
  }
  const result = await axios.get(requestUrl, requestConfig);
  return result.data;
};

export const postRequest = async (url: string, body?: object, config?: AxiosRequestConfig, isOpenseaUrl = false, isNettyWorth = false, isQuicknode = false) => {
  const requestConfig = { ...config };
  let requestUrl;

  if (isNettyWorth) {
    requestUrl = NETTY_URL + url;
  } else if (isOpenseaUrl) {
    requestUrl = OPENSEA_URL + url;
  } else if (isQuicknode) {
    requestUrl = QUICKNODE_URL + url;
  } else {
    requestUrl = BASE_URL + url;
  }

  const token = getCookie('_token');
  if (token) {
    requestConfig.headers = {
      ...requestConfig?.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  const result = await axios.post(requestUrl, body, requestConfig);

  return result.data;
};

export const putRequest = async (url: string, body?: Record<string, any>, config?: AxiosRequestConfig, withAuth = true) => {
  const token = getCookie('_token');

  if (withAuth) {
    config = {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: `Bearer ${token}`,
      },
      // withCredentials: true,
    };
  }

  const result = await axios.put(NETTY_URL + url, body, config);

  return result.data;
};

export const deleteRequest = async (url: string, data?: Record<string, any>, config?: AxiosRequestConfig, withAuth = true) => {
  const token = getCookie('_token');

  if (withAuth) {
    config = {
      ...config,
      data,
      headers: {
        ...config?.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  const result = await axios.delete(NETTY_URL + url, config);

  return result.data;
};

export const getCollectionStat = async (collectionSlug: string) => {
  return getRequest(`/asset_contract/${collectionSlug}`, undefined, true);
};

export const getRelevantCollection = async (ownerAddress: string) => {
  return getRequest(
    `/collections?asset_owner=${ownerAddress}`,
    {
      params: {
        offset: 0,
        limit: 300,
      },
    },
    true
  );
};

export const getAssetsDetails = async (asset: { collectionAddress: string; collectionTokenId: string }) => {
  return axios.get(`${NETTY_URL}/nftb/value?collectionAddress=${asset.collectionAddress}&tokenId=${asset.collectionTokenId}`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
};
export interface FavoriteProps {
  itemId: number | string;
  category: 'NFTSales' | 'CryptoSales';
}

export const toggleFavoriteApi = (payload: FavoriteProps) => {
  return putRequest('/favorites/toggle', payload);
};

export const postQuicknode = (url: string, body?: any, config?: AxiosRequestConfig) => {
  return postRequest(url, body, config, false, false, true);
};

export const getUserFav = async <T = FavoriteType>(params: { category: T }): Promise<T extends 'NFTSales' ? { itemId: string }[] : Record<number, IData>> => {
  return getRequest('/favorites', { params }, false, true);
};

export const getTopSalesDetailFilters = async (slug: string) => {
  return postRequest(`/nft-top-sales/api/assets/traits/${slug}`);
};

export const getTopSalesDetailNfts = async (slug: string, body: { traits: string }, params: TopSalesDetailNftsParams) => {
  return postRequest(`/nft-top-sales/api/assets/${slug}`, body, { params });
};

export const getNewsData = async (params: FetchNewsParams) => {
  const res = await axios.get('https://wp.nettyworth.io/wp-json/wp/v2/posts', { params });
  return res;
};

export const saveWallet = async (walletData: ISaveWallet) => {
  const res = await postRequest('/eth/wallets', walletData, undefined, false, true);
  return res;
};

export const getCryptoCurrency = async (params: CryptoCurrencyParams): Promise<ICryptoListings> => {
  const res = await getRequest('/crypto/prices', { params }, false, true);
  return res;
};
export const getNotifications = async () => {
  const res = await getRequest('/notifications', {}, false, true);
  return res;
};

export const setNotifications = async (alertCategory: { category: AlertCategory; value: boolean }) => {
  const res = await postRequest('/notifications/settings', alertCategory, undefined, false, true);
  return res;
};
export const getCryptoFilters = async () => {
  const res = await axios.get(`${NETTY_URL}/crypto/fiat`);
  return res.data;
};

export const registerUser = async (body: Record<string, any>): Promise<{ message: string }> => {
  const res = await postRequest('/users/register', body, undefined, false, true);
  return res;
};

export const registerWithDynamic = async (body: { email: string; token: string }): Promise<{ message: 'exist' | 'created' }> => {
  const res = await postRequest('/users/dynamic-register', body, undefined, false, true);
  return res;
};

export const getAllUsers = async (params: { search: string; limit?: number }): Promise<Pick<IUserDetails, 'id' | 'email' | 'name' | 'profile_picture' | 'profile_url'>[]> => {
  const res = await getRequest('/users', { params }, false, true);
  return res;
};

export const verifyRegister = async (body: { token: string; email: string; password: string }): Promise<{ message: string }> => {
  const res = await postRequest('/users/verify-email', body, undefined, false, true);
  return res;
};

export const verifyResend = async (body: { email: string }): Promise<{ message: string }> => {
  const res = await postRequest('/users/resend-verify', body, undefined, false, true);
  return res;
};

export const getUserDetail = async (params: { address?: string }): Promise<UserDetailRes> => {
  const res = await getRequest('/users/profile', { params: params?.address ? { address: params?.address } : null }, false, true);
  return res;
};

export const updateUserDetail = async (body: Partial<Pick<IUserDetails, 'email' | 'name' | 'description' | 'location' | 'website'>> & { isNewUser?: boolean }) => {
  const res = await putRequest('/users/updateprofile', body);
  return res;
};

export const loginUser = async (credentials: { email: string; pwd: string }) => {
  const res = await postRequest('/auth', credentials, { withCredentials: true }, false, true);
  return res;
};

export const passwordReset = async () => {
  const res = await getRequest('/users/password-reset', undefined, false, true);
  return res;
};

export const passwordUpdate = async (body: { password: string; token: string; email: string }, url: 'forgot-password' | 'password-reset') => {
  const res = await postRequest(`/users/${url}`, body, undefined, false, true);
  return res;
};

export const forgotPassword = async (body: { email: string }) => {
  const res = await postRequest('/users/reset-password', body, undefined, false, true);
  return res;
};

export const uploadUserImg = async (body: FormData) => {
  const res = await postRequest(
    '/users/updateProfileImage',
    body,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    false,
    true
  );
  return res;
};

export const logoutUser = async () => {
  const token = getCookie('_token');
  try {
    const res = await axios.get(`${NETTY_URL}/logout`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    clearCookies();

    return res;
  } catch (e) {
    throw new Error('');
  }
};

export const refreshToken = async () => {
  const token = getCookie('_token');

  return axios.get(`${NETTY_URL}/refresh`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  });
};

export const getCryptoDetails = async (params: IQuoteLatestParams): Promise<IQuoteLatest> => {
  const res = await axios.get(`${NETTY_URL}/crypto/quote`, { params });
  return res.data;
};

export const getCryptoMetadata = async <T = any>(params: { symbol?: string; skip_invalid?: boolean; address?: string }): Promise<T> => {
  const res = await axios.get(`${NETTY_URL}/crypto/metadata`, { params });
  return res.data;
};

export const getCryptoMap = async (params: { start?: number; limit?: number; symbol: string }) => {
  const res = await axios.get(`${NETTY_URL}/crypto/currencies`, { params });
  return res.data;
};
export const getCryptoNews = async (params: FetchCryptoNewsParams) => {
  const res = await axios.get('https://cryptonews-api.com/api/v1/category', {
    params: {
      ...params,
      token: process.env.NEXT_PUBLIC_CRYPTO_NEWS_KEY,
    },
  });
  return res.data;
};

export const getCryptoStats = async (
  params: CryptoCurrencyStatParams
): Promise<{
  data: {
    [key: string]: ISymbol;
  };
}> => {
  const res = await axios.get(`${NETTY_URL}/crypto/stats`, { params });
  return res.data;
};

export const postTwitter = async () => {
  const res = await postRequest('/users/post-twitter', undefined, undefined, false, true);
  return res.data;
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error) {
      if (error?.response?.status === 429) {
        return axios.request(error.config);
      }
      if (error?.response?.status === 403 || error?.response?.data === 'jwt expired') {
        localStorage.removeItem('dynamic_authentication_token');
        clearCookies();
        Router.push('/');
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);
