import React, { useCallback, useEffect, useRef, useState } from 'react';

import CameraIcon from '@/assets/icons/camera.svg';
import XmarkIcon from '@/assets/icons/icon-xmark.svg';
import type { UserDetailRes } from '@/redux/wallet/model';
import { AWSImageConverter } from '@/utils/helper';

interface UserImageUploaderProps {
  userDetail: UserDetailRes | null;
  validateErr: {
    name: string;
    email: string;
    profile_picture: string;
  };
  onFileSelect?: (file: File) => void;
  maxFileSize?: number;
  acceptedFileTypes?: string[];
}

const UserImageUploader: React.FC<UserImageUploaderProps> = ({ userDetail, validateErr, onFileSelect, maxFileSize = 5 * 1024 * 1024, acceptedFileTypes = ['image/jpeg', 'image/png', 'image/gif'] }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectFile, setSelectFile] = useState<File | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const objectURL = selectFile && URL.createObjectURL(selectFile);

  const errorMessage = React.useMemo(() => {
    return validateErr?.profile_picture || error;
  }, [validateErr, error]);

  const validateFile = useCallback(
    (file: File): boolean => {
      setError(null);

      if (!acceptedFileTypes.includes(file.type)) {
        setError('Please upload a valid image file (JPEG, PNG, or GIF)');
        return false;
      }

      if (file.size > maxFileSize) {
        setError(`File size should be less than ${(maxFileSize / (1024 * 1024)).toFixed(1)}MB`);
        return false;
      }

      return true;
    },
    [acceptedFileTypes, maxFileSize]
  );

  const processFile = useCallback(
    async (file: File) => {
      if (!validateFile(file)) return;

      setIsLoading(true);

      try {
        setSelectFile(file);
        onFileSelect?.(file);
      } catch (err) {
        setError('Error processing file. Please try again.');
        console.error('File processing error:', err);
      } finally {
        setIsLoading(false);
      }
    },
    [onFileSelect, validateFile]
  );

  const handleFileSelect = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { target } = e;
      if (target?.files?.[0]) {
        await processFile(target.files[0]);
      }
    },
    [processFile]
  );

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  }, []);

  const handleDrop = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);

      const file = e.dataTransfer.files[0];

      if (file) {
        await processFile(file);
      }
    },
    [processFile]
  );

  const removeFile = useCallback(() => {
    setSelectFile(null);
    setError(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, []);

  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      fileInputRef.current?.click();
    }
  }, []);

  useEffect(() => {
    return () => {
      if (objectURL) {
        URL.revokeObjectURL(objectURL);
      }
    };
  }, [objectURL]);

  return (
    <div className='flex flex-col lg:items-center gap-4 ss:gap-6 !bg-transparent'>
      <h3 className='lg:hidden text-coal-250 dark:text-white text-base/5 ss:text-lg font-medium' id='upload-heading'>
        User Image
      </h3>

      <div className={`!bg-transparent relative ${dragActive ? 'border-primary' : 'border-gray-300'}`} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} onKeyDown={handleKeyDown} role='button' tabIndex={0} aria-labelledby='upload-heading' aria-haspopup='true'>
        <div className='size-[110px] !bg-[#323232b3] rounded-full overflow-hidden flex justify-center items-center' aria-live='polite' aria-busy={isLoading}>
          {isLoading ? (
            <div className='text-white' role='status' aria-label='Image upload in progress'>
              Loading...
            </div>
          ) : userDetail?.profile_picture ? (
            <img src={objectURL || AWSImageConverter(userDetail.profile_picture)} alt='Profile' className='w-full h-full object-cover' aria-label='Current profile picture' />
          ) : (
            <CameraIcon className='size-11' aria-label='Upload profile picture' />
          )}
        </div>

        <input ref={fileInputRef} type='file' className='absolute m-auto inset-0 w-full h-full opacity-0 cursor-pointer' onChange={handleFileSelect} accept={acceptedFileTypes.join(',')} aria-label='Upload profile picture' />

        {dragActive && (
          <div className='absolute inset-0 w-full h-full m-auto flex justify-center items-center rounded-full bg-black bg-opacity-25' role='alert' aria-label='Drag and drop zone'>
            <span className='text-white font-medium'>Drop here</span>
          </div>
        )}
      </div>

      <button type='button' className='max-lg:hidden text-sm font-bold py-2 px-8 text-white !bg-jet-black rounded-3xl' aria-label='Upload image button' onClick={() => fileInputRef.current?.click()}>
        upload
      </button>

      {errorMessage && (
        <span className='text-red-400' role='alert' aria-live='assertive'>
          {errorMessage}
        </span>
      )}

      {selectFile && (
        <div className='flex gap-[18px] items-center bg-light-gray py-2 px-4 rounded w-max dark:bg-jet-black' onClick={removeFile} role='button' tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && removeFile()} aria-label='Remove selected file'>
          <span>{selectFile.name}</span>
          <XmarkIcon className='fill-white w-[13px] h-[13px]' aria-hidden='true' />
        </div>
      )}
    </div>
  );
};

export default UserImageUploader;
