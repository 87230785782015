import { useMemo } from 'react';

import useCurrency from '@/hooks/query/useCurrency';
import useGasPrice from '@/hooks/query/useGasPrice';
import { USDDollarFormatter } from '@/utils/formatter';

import EthIcon from '../../assets/icons/icon-eth.svg';
import GasIcon from '../../assets/icons/icon-gas.svg';

const LiveEthGasInfoBox = () => {
  const { data: ethData } = useCurrency();
  const { data: gasData } = useGasPrice();
  const ethValue = ethData?.ethusd || 0;

  const gasValue = gasData?.ProposeGasPrice || 0;
  const boxInfo = useMemo(() => {
    return [
      {
        id: 0,
        Icon: EthIcon,
        value: USDDollarFormatter(Number(ethValue), 'USD'),
      },
      {
        id: 1,
        Icon: GasIcon,
        value: Number(Number(gasValue).toFixed(3)),
      },
    ];
  }, [ethValue, gasValue]);

  return (
    <div className='py-2 px-[18px] flex justify-between rounded bg-white-100 dark:!bg-coal w-max min-w-52'>
      {boxInfo.map(({ Icon, value, id }) => {
        return (
          <div key={id} className='!bg-transparent flex items-center gap-[6px]'>
            <Icon />
            <p className='text-xs font-normal'>{value}</p>
          </div>
        );
      })}
    </div>
  );
};

export default LiveEthGasInfoBox;
