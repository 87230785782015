import { usePathname, useRouter } from 'next/navigation';
import { Sheet } from 'react-modal-sheet';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '@/hooks/redux';
import useAuthenticate from '@/hooks/useAuthenticate';
import { useTheme } from '@/hooks/useTheme';
import { togglePanel } from '@/redux/panel/panelSlice';
import { cn } from '@/utils/helper';

import { toggleMoreMenu } from '../../../redux/modal/modalSlice';
import { mobileSideBarLinks } from '../constants';
import MenuModal from './MenuModal';

const MobileStickyBottomNav: React.FC<{ dark?: boolean }> = ({ dark }) => {
  const pathname = usePathname();
  const router = useRouter();
  const dispatch = useDispatch();
  const { moreModal } = useAppSelector((state) => state.modal);
  const { isAuth } = useAuthenticate();
  const handleNavigate = (link: string, newTab?: boolean) => {
    if (newTab) window.open(link, '_blank');
    else router.push(link);
  };
  // const activePanelIsOpen = useAppSelector((state) => state.panel.activePanel);
  const { mode } = useTheme();
  const theme = dark ? 'dark' : mode;
  // const modifiedMobileLinks = isAuth ? mobileSideBarLinks.filter((item) => item.link !== '/') : mobileSideBarLinks.filter((item) => item.link !== '/portfolio');

  // const menuLinks = activePanelIsOpen ? mobileSideBarLinks.filter((item) => item.title !== 'Menu') : mobileSideBarLinks.filter((item) => item.title !== 'Rewards');

  const menuLinks = mobileSideBarLinks.filter((item) => item.title !== 'Menu');

  return (
    <>
      <nav className={cn(theme === 'light' ? 'bg-light-blue-grey' : 'dark:bg-jet-black', 'border-t dark:[&_button]:!bg-transparent fixed bottom-0 left-0 right-0 flex items-center justify-between z-40 px-[27px] py-5 lg:hidden gap-1')}>
        {menuLinks.map(({ Icon, link, title, modal, isPrivate, newTab }) => {
          const isActive = pathname?.includes(link);

          return (
            <button
              key={title}
              className={cn('focus:outline-none last:text-center flex flex-col justify-end items-center text-white h-full w-12 gap-0.5')}
              onClick={() => {
                if (modal || moreModal) {
                  dispatch(toggleMoreMenu());
                  return;
                }
                if (isAuth || isPrivate) {
                  handleNavigate(link, newTab);
                } else if (!modal) {
                  handleNavigate('/login');
                }
                dispatch(togglePanel(null));
              }}
            >
              {
                <Icon
                  className={cn(
                    // Conditional class for 'Rewards'
                    title === 'Rewards' ? (theme === 'dark' ? '[&_path]:stroke-white stroke-white' : '[&_path]:stroke-coal-250') : theme === 'dark' ? '[&_path]:fill-white' : '[&_path]:fill-coal-250',

                    isActive && '[&_path]:fill-primary [&_path]:stroke-primary  stroke-primary',

                    // Size class
                    'size-5'
                  )}
                />
              }
              <span className={cn(pathname?.includes(link) ? 'text-primary' : theme === 'light' ? 'text-coal-250' : ' text-white', isActive && '!text-primary')}>{title}</span>
            </button>
          );
        })}
      </nav>
      <Sheet isOpen={moreModal} onClose={() => dispatch(toggleMoreMenu())} detent='content-height' className={`${theme}-sheet lg:hidden`}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <MenuModal />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
};

export default MobileStickyBottomNav;
