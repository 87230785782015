import React from 'react';

import SunIcon from '@/assets/icons/icon-brightness.svg';
import useNavigationColor from '@/hooks/useNavigationColor';
import { useTheme } from '@/hooks/useTheme';
import { cn } from '@/utils/helper';

const ChangeThemeButton: React.FC = () => {
  const { handleSetTheme, mode } = useTheme();
  const navColor = useNavigationColor();

  return (
    <button onClick={() => handleSetTheme(mode === 'light' ? 'dark' : 'light')} className={cn('w-8 h-8 ss:w-10 ss:h-10 flex justify-center items-center rounded-full ', navColor ? 'bg-white-100 dark:!bg-jet-black-100' : '!bg-light-blue-grey dark:!bg-char-coal lg:dark:!bg-jet-black')}>
      <SunIcon className={cn('fill-coal-250 dark:!fill-primary [&_path]:!fill-coal-250 w-[17px] relative transition-transform duration-1000 transform', mode === 'light' ? 'translate-x-0' : 'translate-x-2 opacity-0 w-0')} />
      <SunIcon className={cn('fill-coal-250 dark:!fill-primary dark:[&_path]:!fill-white size-4 relative transition-transform duration-1000 transform', mode === 'dark' ? 'translate-x-0' : '-translate-x-2 opacity-0 w-0')} />
    </button>
  );
};

export default ChangeThemeButton;
