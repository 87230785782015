import Link from 'next/link';
import { usePathname } from 'next/navigation';

import CloseIcon from '@/assets/icons/icon-thick-close.svg';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { toggleMoreMenu } from '@/redux/modal/modalSlice';
import { cn } from '@/utils/helper';

import { MORE_LEGAL_LINK_ITEMS, MORE_LINK_ITEMS } from '../constants';

const MoreNav = () => {
  const pathname = usePathname();
  const dispatch = useAppDispatch();
  const { moreModal } = useAppSelector((state) => state.modal);
  return (
    <div className={cn('bg-white dark:bg-jet-black absolute h-full z-[90] top-0 -right-[248px] pt-[33px] w-[248px] transition-all ease-in-out duration-300', moreModal ? 'visible opacity-100 translate-x-0' : 'invisible opacity-0 -translate-x-full')}>
      <div className='pr-6 flex justify-end'>
        <button onClick={() => dispatch(toggleMoreMenu())}>
          <CloseIcon className='fill-primary' />
        </button>
      </div>
      <div className='mt-6 pl-[47px] pb-7 border-b border-b-coal-border'>
        <h3 className='text-lg/9 font-semibold text-coal-250'>Explore</h3>
        <div className='flex flex-col'>
          {MORE_LINK_ITEMS.map((item) => {
            return (
              <Link target={item.newTab ? '_blank' : '_self'} key={item.link} href={item.path} onClick={() => dispatch(toggleMoreMenu())}>
                <span className={cn('text-base/[34px] hover:text-primary', pathname?.includes(item.path) ? 'text-primary' : '')}>{item.link}</span>
              </Link>
            );
          })}
        </div>
      </div>
      <div className='mt-[20px] pl-[47px]'>
        <h3 className='text-lg/9 font-semibold text-coal-250'>Legal</h3>
        {MORE_LEGAL_LINK_ITEMS.map((legal) => {
          return (
            <Link key={legal.path} href={legal.path} className='block'>
              <span className={cn('text-base/[34px] hover:text-primary', pathname?.includes(legal.path) ? 'text-primary' : '')}>{legal.link}</span>
            </Link>
          );
        })}
      </div>
      <div className='mt-[19px] border-t pt-[25px] pl-[47px] border-coal-border'>
        <p className='text-sm/[22px] text-coal-250 font-normal'>&copy; {new Date().getFullYear()} NettyWorth </p>
        <p className='text-sm/[22px] text-coal-250 font-normal'>All Rights Reserved</p>
      </div>
    </div>
  );
};
export default MoreNav;
