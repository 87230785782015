import { useAppDispatch, useAppSelector } from './redux';
import { toggleSound } from '../redux/sound/soundSlice';
import { removeCookie, setCookie } from '../utils/cookies';

const useSoundMode = () => {
  const { mode } = useAppSelector((state) => state.sound);
  const dispatch = useAppDispatch();
  const handleSoundMode = (theme: 'on' | 'off') => {
    dispatch(toggleSound(theme));
    if (mode === 'off') {
      setCookie('sound', theme);
    } else {
      removeCookie('sound');
    }
  };

  return { soundMode: mode, handleSoundMode };
};

export { useSoundMode };
