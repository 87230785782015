import Link from 'next/link';
import { useRouter } from 'next/router';
import { createPortal } from 'react-dom';
import { Sheet } from 'react-modal-sheet';

import IconCancel from '@/assets/icons/icon-cancel.svg';
import ElipseIcon from '@/assets/icons/icon-circle-more.svg';
import UpIcon from '@/assets/icons/icon-thich-up.svg';
import Logo from '@/assets/icons/logo.svg';
import CreatePostModal from '@/components/feed/create-post-modal';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import useAuthenticate from '@/hooks/useAuthenticate';
import useFeedProfile from '@/hooks/useFeedProfile';
import useNotificationSocket from '@/hooks/useNotificationSocket';
import { actionCreatePost, selectMoreMenu, toggleMoreMenu } from '@/redux/modal/modalSlice';
import { togglePanel } from '@/redux/panel/panelSlice';
import { cn } from '@/utils/helper';

import { sideBarLinks } from '../constants';
import MobileStickyBottomNav from './MobileStickyBottomNav';
import MoreNav from './MoreNav';

const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const moreMenu = useAppSelector(selectMoreMenu);
  const router = useRouter();

  const { mode } = useAppSelector((state) => state.theme);
  const { moreModal, modalcreatePost } = useAppSelector((state) => state.modal);
  const pathname = router.asPath;
  const { isMyProfile } = useFeedProfile();
  const { isAuth } = useAuthenticate();

  const { activePanel } = useAppSelector((state) => state.panel);
  useNotificationSocket();

  const handleNavigate = () => {
    router.push(isAuth ? '/portfolio' : '/');
    dispatch(togglePanel(null));
  };

  const handleActionPost = () => {
    // if (pathname.includes('/portfolio')) {
    //   handleBuyCrypo();
    // } else {
    dispatch(actionCreatePost());
    // }
  };

  const filteredLinks = isAuth ? sideBarLinks.filter((item) => item.link !== '/') : sideBarLinks;

  return (
    <div className={cn('col-span-1 row-span-1 pt-[35px] pl-5 lg:pl-6 pb-[22px] w-full lg:w-auto lg:rounded-b-none lg:p-0 lg:dark:bg-transparent z-50 lg:z-30 transition-all ease-linear duration-200 top-0 border-b dark:!bg-jet-black !bg-white sticky h-screen hidden lg:flex')}>
      <div className={cn('dark:!bg-transparent lg:h-screen overflow-y-auto scrollbar-hide w-[90px] !bg-white flex flex-col relative lg:justify-start mt-1 ss:mt-2 lg:mt-0 dark:!bg-jet-black z-[91] dark:border-r-coal-border border-r')}>
        <div className='flex flex-col lg:pb-9 dark:!bg-transparent'>
          <div className='flex flex-col items-center gap-20 lg:pb-8 dark:!border-b-coal-border border-b lg:pt-[29px] lg:px-[34px] dark:!bg-transparent dark:!bg-jet-black !bg-white'>
            <button onClick={handleNavigate} className='dark:!bg-transparent'>
              <Logo />
            </button>
          </div>
          <div className='hidden flex-col gap-6 py-8 lg:flex'>
            {filteredLinks.map(({ Icon, ...item }) => {
              const checkPath = item.link.includes('/loans') && pathname.includes('/loans');
              const isActive = !moreMenu && (checkPath || (pathname.includes(item.link) && !pathname.includes('/feed/')));

              return (
                <Link key={item.title} href={item.link} target={item.newTab ? '_blank' : '_self'} className='cursor-pointer flex items-center gap-[5px] rounded-sm text-xs hover:bg-white group/link sideBarLink dark:!bg-transparent hover:w-full flex-col'>
                  <Icon className={cn(isActive && '[&_path]:fill-primary dark:[&_path]:fill-primary-blue-100 dark:[&_circle]:fill-primary/15 [&_circle]:fill-primary/15')} />
                  <span className={cn('text-[10px] font-normal dark:group-hover/link:text-primary-blue-100 group-hover/link:text-primary', isActive ? '!text-primary dark:!text-primary-blue-100' : 'text-coal-250')}>{item.title}</span>
                </Link>
              );
            })}
            {/* <Link href={`/feed/${userDetail?.profile_url}`} className={cn('cursor-pointer flex items-center gap-[17px] rounded-sm text-xs hover:bg-white  [&_span]:hover:text-primary sideBarLink dark:!bg-transparent hover:w-full')}>
              <ProfileIcon className='fill-coal-250 dark:fill-white dark:[&_path]:fill-white' />
              <span className={cn('text-base font-normal', pathname.includes('/feed/') ? 'text-primary' : 'text-coal-250')}>Profile</span>
            </Link> */}
            <button className='flex items-center justify-between dark:!bg-transparent flex-col gap-[5px] group/button' onClick={() => dispatch(toggleMoreMenu())}>
              <ElipseIcon className={cn(moreModal ? '[&_path]:fill-primary dark:[&_path]:fill-primary-blue-100' : 'fill-coal-250')} />
              <span className={cn('text-[10px] font-normal group-hover/button:text-primary dark:group-hover/button:text-primary-blue-100', moreModal ? 'text-primary dark:text-primary-blue-100' : '')}>More</span>
            </button>
          </div>
          {/* <div className='px-[37px] py-5 hidden lg:block'>
            <Link href='/feed' className='flex justify-center items-center py-[10px] !bg-primary rounded'>
              <p className='text-white font-bold text-base/normal'>Create a Post</p>
            </Link>
          </div> */}
          {/* {userChannel.length ? (
            <div className='pt-9 pb-10 px-10 hidden lg:block'>
              <ChannelsContainer text='My Channels' channels={userChannel} loading={userChannelLoading} />
            </div>
          ) : null}
          {subscribedChannels?.length ? (
            <div className='pt-9 pb-10 px-10 hidden lg:block'>
              <ChannelsContainer
                text='Channels'
                channels={subscribedChannels?.map((ch) => {
                  return {
                    chanel_type: ch.channel.chanel_type,
                    createdAt: ch.channel.createdAt,
                    description: ch.channel.description,
                    id: ch.channel.id,
                    slug: ch.channel.slug,
                    img_url: ch.channel.img_url,
                    invite: ch.channel.invite,
                    isPrivate: ch.channel.isPrivate,
                    name: ch.channel.name,
                    plan_type: ch.channel.plan_type,
                    share_links: ch.channel.share_links,
                    updatedAt: ch.channel.updatedAt,
                    userId: ch.userId,
                    messages: ch.channel.messages,
                    banner_image: ch.channel.banner_image,
                    subchannels: ch.channel.subchannels,
                    parentId: null,
                  };
                })}
                loading={subscribedChannelsLoading}
              />
            </div>
          ) : null} */}
          {/* <div className='pt-9 pb-10 px-10 hidden lg:block'>
            <ChannelsContainer text='Popular Channels' channels={popularChannels?.pages[0].data} loading={popularChannelsLoading} />
          </div> */}
        </div>
      </div>
      {createPortal(
        <div>
          {activePanel === null && !moreModal && (pathname === '/feed' || isMyProfile) && !modalcreatePost && (
            <div className='fixed bottom-[100px] w-full flex justify-end z-[1000] !bg-transparent'>
              <button className='mr-5 w-max px-6 !bg-primary rounded-[43px] h-10 gap-[10px] p-[5px] flex justify-center items-center text-white text-sm font-semibold lg:hidden' onClick={handleActionPost}>
                {pathname.includes('/portfolio') ? 'Buy' : 'Post'}
                <UpIcon className='fill-white' />
              </button>
            </div>
          )}
          {!modalcreatePost && <MobileStickyBottomNav />}
        </div>,

        document.body
      )}
      <Sheet isOpen={modalcreatePost} onClose={handleActionPost} detent='content-height' className={`${mode}-sheet sheet-post lg:hidden`}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <CreatePostModal />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
      {modalcreatePost &&
        createPortal(
          <div className='absolute h-dvh top-0 w-full z-[9999] backdrop-blur-md flex justify-end'>
            <button className='h-max mt-5 mr-[14px]' onClick={handleActionPost}>
              <IconCancel />
            </button>
          </div>,
          document.body
        )}
      <div className='hidden lg:block'>
        <MoreNav />
      </div>
    </div>
  );
};

export default Sidebar;
