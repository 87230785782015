import { useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';

import AttachIcon from '@/assets/icons/icon-attach.svg';
import GifIcon from '@/assets/icons/icon-gif.svg';
import UserIcon from '@/assets/icons/icon-head-dark.svg';
import UpIcon from '@/assets/icons/icon-thich-up.svg';
import { useSocket } from '@/components/providers/socket-provider';
import TinyEditor from '@/components/shared/message-editor';
import Popup from '@/components/shared/popup';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import usePostSocket from '@/hooks/usePostSocket';
import { deleteSelectedPostAction } from '@/redux/feed/feedSlice';
import { actionCreatePost } from '@/redux/modal/modalSlice';
import { AWSImageConverter, cn } from '@/utils/helper';

import MemoizedPostFiles from './post-files';
import { EDIT_POST } from '../../socket/post/constant';

const GifPicker = dynamic(() => import('gif-picker-react'), { ssr: false });
interface ICreatePostStyle {
  stylesContent?: {
    direction: string;
    name: string;
    image: string;
    postBtn: string;
    borderAttach: string;
    mode: string | null;
  };
}
const CreatePost = ({ stylesContent }: ICreatePostStyle) => {
  const { selectedPostId, posts } = useAppSelector((state) => state.feed);
  const { userDetail } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { socket } = useSocket();
  const { editorHeight, handleEditHeight, handleFileSelect, handlePostText, handleSendMessage, postText, selectedFiles, deleteSelectedFile, selectedGif, handleSelectGif, deleteSelectedGif, filesLoading, progress } = usePostSocket();
  const [openGifPicker, setOpenGifPicker] = useState(false);
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/png': [],
      'image/jpeg': [],
      'video/*': [],
    },
    onDrop: (accepted) => {
      accepted.forEach((val) => {
        handleFileSelect(val);
      });
    },
    onDropRejected() {
      toast.error('max 4 files allowed');
    },
    maxFiles: 4,
    noClick: true,
  });
  const selectedPost = selectedPostId ? posts.data.find((post) => post.id === selectedPostId) : null;

  useEffect(() => {
    if (selectedPost) {
      handlePostText(selectedPost.text);
    } else {
      handlePostText('');
    }
  }, [selectedPost]);

  const deleteSelectedPost = () => {
    dispatch(deleteSelectedPostAction());
  };

  const editPost = () => {
    if (socket && selectedPost) {
      socket.emit(EDIT_POST, {
        ...selectedPost,
        text: postText,
      });
      handlePostText('');
      deleteSelectedPost();
    }
  };

  return (
    <div className={cn('flex justify-center items-center flex-col gap-5 sm:gap-[29px] dark:!bg-transparent lg:pt-[37px]', stylesContent ? 'px-0' : ' px-5 sm:px-0')}>
      <div {...getRootProps()} className={cn('bg-light-blue-grey flex flex-col w-full pt-6 pb-[14px] sm:pt-4 sm:pb-3 justify-between rounded dark:border dark:border-midnight-gray', stylesContent?.mode === 'dark' ? 'bg-char-coal' : '', stylesContent ? 'px-0' : 'pl-[17px] pr-[14px] sm:pl-6 sm:pr-3')}>
        <input {...getInputProps()} />
        <div className={cn('flex items-center gap-4', stylesContent?.direction)}>
          <div className='self-start flex items-center gap-[10px]'>
            {userDetail?.profile_picture ? (
              <div className='border rounded-full self-start w-max'>
                <img alt='user-image' src={AWSImageConverter(userDetail.profile_picture)} className={cn('rounded-full object-cover min-w-[35px] w-[35px] h-[35px]', stylesContent?.image)} />
              </div>
            ) : (
              <div className='border rounded-full self-start'>
                <UserIcon className='size-[45px] sm:size-9 rounded-full' />
              </div>
            )}
            {stylesContent?.name && <span className='text-base font-semibold text-primary'>{userDetail?.name}</span>}
          </div>
          <div className='w-full'>
            <TinyEditor
              placeholder='Share your thoughts, art, or videos ...'
              handleSend={() => {
                return selectedPost ? editPost() : handleSendMessage({});
              }}
              currenValue={postText}
              handleEditorChange={handlePostText}
              editorHeight={editorHeight}
              handleEditorHeight={handleEditHeight}
              handleFiles={handleFileSelect}
            />
          </div>
        </div>
        {selectedFiles.length || selectedGif ? <MemoizedPostFiles deleteSelected={deleteSelectedFile} selectedFiles={selectedFiles} deleteSelectedGif={deleteSelectedGif} selectedGif={selectedGif} /> : null}
        {filesLoading ? (
          <div className='w-full px-2 lg:px-0 mt-2'>
            <div className='flex mt-4 lg:mt-0 w-full h-5 mt bg-slate-300 rounded-full overflow-hidden dark:!bg-char-coal' role='progressbar' aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100}>
              <div className='flex flex-col justify-center rounded-full overflow-hidden !bg-primary text-[8px] lg:text-sm text-white text-center whitespace-nowrap transition duration-500' style={{ width: `${progress}%` }}>
                {progress === 100 ? 'loading file' : `${progress}%`}
              </div>
            </div>
          </div>
        ) : null}
        <div className={cn('flex mt-3 gap-[10px] items-center justify-end', stylesContent?.borderAttach, stylesContent?.mode === 'dark' ? 'border-midnight-gray' : 'border-shade-blue-grey')}>
          <button disabled={selectedFiles.length > 3 || !!selectedGif} className='size-10 sm:size-[29px] rounded-full disabled:bg-btn-disabled bg-white flex justify-center items-center' onClick={() => (userDetail ? open() : router.push('/register'))}>
            <AttachIcon className='w-[9px] h-[15px] rotate-45 dark:fill-white fill-coal-250' />
          </button>
          <button disabled={!!selectedGif || !!selectedFiles.length} onClick={() => (userDetail ? setOpenGifPicker(true) : router.push('/register'))} className='size-10 sm:size-[29px]  disabled:bg-btn-disabled rounded-full bg-white flex justify-center items-center'>
            <GifIcon className='w-[15px] h-[15px] dark:fill-white fill-coal-250' />
          </button>
          {selectedPost ? (
            <button className='bg-white border border-coal-250 rounded max-w-[75px] p-[5px] flex justify-center items-center w-full' onClick={() => deleteSelectedPost()}>
              <span className='text-sm font-semibold'>Cancel</span>
            </button>
          ) : null}
          <button
            disabled={filesLoading}
            className={cn('!bg-primary disabled:!bg-btn-disabled rounded h-10 sm:h-auto max-w-[120px] sm:max-w-[75px] p-[5px] flex justify-center items-center w-full', stylesContent?.postBtn)}
            onClick={() => {
              if (selectedPost) {
                editPost();
              } else {
                handleSendMessage({});
              }
              if (stylesContent) {
                dispatch(actionCreatePost());
                const el = document.getElementById('scroll');
                if (el) {
                  el?.scrollTo({ top: 0, behavior: 'smooth' });
                }
              }
            }}
          >
            <span className='text-white text-sm font-semibold'>{selectedPost ? 'Save' : 'Post'}</span>
            {stylesContent && <UpIcon className='fill-white' />}
          </button>
        </div>
      </div>
      <Popup
        wrapperClassname='z-[9999999999]'
        onClose={() => {
          setOpenGifPicker(false);
        }}
        isOpen={openGifPicker}
      >
        <GifPicker
          onGifClick={(gif) => {
            handleSelectGif(gif.preview.url);
            setOpenGifPicker(false);
          }}
          tenorApiKey={process.env.NEXT_PUBLIC_TENOR_GIF_KEY}
        />
      </Popup>
    </div>
  );
};

export default CreatePost;
