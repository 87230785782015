import { useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { uploadPostFiles } from '@/api/feedAPI';

const useUploadFiles = () => {
  const [progress, setProgress] = useState(0);

  const handleProgress = (percent: number) => {
    setProgress(percent);
  };
  const mutation = useMutation<{ file: string; fileType: string }[], AxiosError, FormData>({
    mutationFn: (variables) => uploadPostFiles(variables, handleProgress),
  });
  return { ...mutation, progress };
};
export default useUploadFiles;
