import ArrowLeftIcon from '@/assets/icons/icon-active-back.svg';
import LeftIcon from '@/assets/icons/icon-left.svg';
import ToggleButton from '@/components/shared/buttons/ToggleButton';
import useNotification from '@/hooks/useNotification';

import { notificationList } from '../constants';

interface NotificationsProps {
  isShow: () => void;
}

const Notifications: React.FC<NotificationsProps> = ({ isShow }) => {
  const { handleSwitchToggle, toggleSwitch } = useNotification();

  return (
    <article className='h-full !bg-transparent lg:dark:!bg-char-coal'>
      <div className='!bg-transparent relative flex items-center justify-center h-8'>
        <button className='absolute left-0 flex justify-center items-center size-6 lg:size-5' onClick={isShow}>
          <LeftIcon className='dark:fill-white fill-dark-blu block lg:hidden' />
          <ArrowLeftIcon className='[&_path]:!fill-primary hidden lg:block' />
        </button>

        <h3 className='text-coal-250 dark:text-white text-base lg:text-lg font-medium'>Notifications</h3>
      </div>

      <div className='flex justify-between items-start gap-4 mt-6 !bg-transparent'>
        {notificationList.map((notif) => {
          return (
            <div key={notif.label} className='!bg-transparent max-w-[205px]'>
              <strong className='text-lg font-medium text-coal-250 dark:text-white'>{notif.label}</strong>
              {notif?.additionalText && <p className='mt-3 text-xs/5 font-medium text-coal-250 dark:!text-[#b2b2b2]'>{notif.additionalText}</p>}
            </div>
          );
        })}

        <div className='flex items-center !bg-transparent'>
          {notificationList.map((notif) => {
            return <ToggleButton key={notif.value} isGrey value={toggleSwitch[notif.value]} onSwitch={() => handleSwitchToggle(notif.value)} />;
          })}
        </div>
      </div>
    </article>
  );
};

export default Notifications;
