import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { getEthCurrency } from '@/api/api';
import { GET_CURRENCY } from '@/utils/queryKey';

const useCurrency = () => {
  const query = useQuery({
    queryKey: [GET_CURRENCY],
    queryFn: () => getEthCurrency(),
  });

  const ethUsd = query.data?.ethusd;

  const convertEthToUsd = useCallback(
    (amount: number | undefined) => {
      return ethUsd && amount ? Number((amount * parseInt(ethUsd, 10)).toFixed(2)) : 0;
    },
    [ethUsd]
  );

  return { ...query, convertEthToUsd };
};

export default useCurrency;
