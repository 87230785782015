import IconClose from '../../../assets/icons/icon-close-small.svg';

interface IPanelLayout {
  children: React.ReactNode;
  onClose: () => void;
  name: string;
  fullText?: string | null;
}

const PanelLayout: React.FC<IPanelLayout> = ({ children, onClose, name, fullText }) => {
  return (
    <div className='h-full !bg-transparent'>
      <div className='w-full !bg-transparent py-4 hidden items-center relative lg:flex'>
        <p className='font-semibold text-lg text-coal-250'>
          {fullText || (
            <>
              Account <span className='text-primary text-lg font-semibold'>{name}</span>
            </>
          )}
        </p>
        <button className='absolute right-0' onClick={onClose}>
          <IconClose className='fill-coal-250 [&_.line]:fill-primary-blue-100' />
        </button>
      </div>
      {children}
    </div>
  );
};

export default PanelLayout;
