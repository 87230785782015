import { useState } from 'react';

import { useRouter } from 'next/router';

import UserIcon from '@/assets/icons/icon-head-dark.svg';
import SearchIcon from '@/assets/icons/icon-search.svg';
import Spinner from '@/components/Spinner';
import useQuoteLatest from '@/hooks/query/useQuoteLatest';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import useGlobalSearch from '@/hooks/useGlobalSearch';
import { togglePanel } from '@/redux/panel/panelSlice';
import { AWSImageConverter, cn } from '@/utils/helper';

const btns = [
  {
    label: 'Users',
    type: 'user',
  },
  {
    label: 'Channels',
    type: 'channel',
  },
  {
    label: 'Crypto',
    type: 'crypto',
  },
];

const SearchPanel = () => {
  const { channels, channelsLoading } = useAppSelector((state) => state.channel);
  const { usersLoading, users } = useAppSelector((state) => state.profile);
  const { topBanner } = useAppSelector((state) => state.modal);
  const [active, setActive] = useState('user');
  const router = useRouter();
  const handleChange = (type: string) => {
    setActive(type);
  };
  const appDispatch = useAppDispatch();
  const { searchValue, handleChannelSearch } = useGlobalSearch();
  const { data: cryptoData, isLoading: cryptoDataLoading } = useQuoteLatest(searchValue);
  const handleNavigate = (link: string, type: string) => {
    router.push(`/${type}/${link}`);
    appDispatch(togglePanel(null));
  };

  return (
    <div className={cn('block lg:hidden mt-12', topBanner && 'mt-[82px]')}>
      <div className='relative '>
        <SearchIcon className='absolute scale-100 fill-grey-lavender left-4 top-4' />
        <input value={searchValue} type='text' onChange={handleChannelSearch} className='w-full bg-shade-blue-grey placeholder:text-coal-250 text-sm py-4 pl-12 pr-6 rounded-lg' placeholder='Search NettyWorth' />
      </div>
      <div className='flex flex-col'>
        <div className='flex gap-[9px] pt-[14px]'>
          {btns.map(({ type, label }) => {
            return (
              <button key={type} className={cn('font-semibold text-sm py-[15px] rounded-lg w-1/2', active === type ? 'bg-white text-primary' : 'bg-shade-blue-grey text-coal-250 opacity-50 ')} onClick={() => handleChange(type)}>
                {label}
              </button>
            );
          })}
        </div>
        {usersLoading === 'pending' && active === 'user' ? (
          <div className='w-12 h-12 my-4 mx-auto'>
            <Spinner />
          </div>
        ) : (
          !!users.length &&
          active === 'user' && (
            <div className='grid grid-cols-2 gap-x-2 gap-y-[9px] py-[29px]'>
              {users.map(({ id, profile_picture, name, profile_url }) => {
                return (
                  <button key={id} className='bg-white rounded flex items-center gap-2 p-[7px]' onClick={() => handleNavigate(profile_url, 'feed')}>
                    {profile_picture ? <img src={AWSImageConverter(profile_picture)} alt='picture' className='w-[66px] h-[66px] rounded-full object-cover' /> : <UserIcon className='w-[66px] h-[66px] rounded-full' />}
                    <div className='flex flex-col'>
                      <span className='text-[13px] font-semibold text-coal-250'>{name}</span>
                      <span className='text-coal-250 text-xs'>#Rank 0</span>
                    </div>
                  </button>
                );
              })}
            </div>
          )
        )}
        {channelsLoading && active === 'channel' ? (
          <div className='w-12 h-12 my-4 mx-auto'>
            <Spinner />
          </div>
        ) : (
          !!channels &&
          !!channels.length &&
          active === 'channel' && (
            <div className='grid grid-cols-2 gap-x-2 gap-y-[9px] py-[29px]'>
              {channels.map(({ id, img_url, name, slug }) => {
                return (
                  <button key={id} onClick={() => handleNavigate(slug, 'channel')} className='bg-white rounded flex items-center gap-2 p-[7px]'>
                    {img_url ? <img src={AWSImageConverter(img_url)} alt='channel-image' className='w-[66px] h-[66px] rounded-full object-cover' /> : <UserIcon className='w-[66px] h-[66px] rounded-full' />}
                    <div className='flex flex-col'>
                      <span className='text-[13px] font-semibold text-coal-250'>{name}</span>
                      <span className='text-coal-250 text-xs'>#Rank 0</span>
                    </div>
                  </button>
                );
              })}
            </div>
          )
        )}
        {cryptoDataLoading && active === 'crypto' ? (
          <div className='w-12 h-12 my-4 mx-auto'>
            <Spinner />
          </div>
        ) : (
          cryptoData &&
          active === 'crypto' && (
            <div className='grid grid-cols-2 gap-x-2 gap-y-[9px] py-[29px]'>
              <button key={cryptoData.id} onClick={() => handleNavigate(cryptoData.slug, 'crypto-prices')} className='bg-white rounded flex items-center gap-2 p-[7px]'>
                <img src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${cryptoData.id}.png`} alt={cryptoData.slug} className='w-[66px] h-[66px] rounded-full object-cover' />
                <div className='flex flex-col'>
                  <span className='text-[13px] font-semibold text-coal-250'>{cryptoData.name}</span>
                </div>
              </button>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default SearchPanel;
