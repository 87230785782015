import { useAccount, useDisconnect } from 'wagmi';

import CancelIcon from '@/assets/icons/icon-cancel-wallet.svg';
import DuplicateIcon from '@/assets/icons/icon-duplicate.svg';
import CopyIcon from '@/assets/icons/icon-share-small.svg';
import useCopy from '@/hooks/useCopy';
import { truncateAddress } from '@/utils/formatter';
import { cn } from '@/utils/helper';

interface IWalletInfoProps {
  className?: string;
}

interface IWalletInfoUIProps extends IUserWalletInfoUIProps {
  address: `0x${string}`;
}

interface IUserWalletInfoUIProps extends IWalletInfoProps {
  children?: React.ReactNode;
  addressClassName?: string;
}

export const WalletInfoUI = ({ address, className, children, addressClassName }: IWalletInfoUIProps) => {
  const { copyHandler } = useCopy(address);

  return (
    <div className={cn('flex items-center justify-between gap-4 bg-coal-50 dark:bg-coal rounded py-[14px] pl-[17px] pr-[19px] ', className)}>
      <div className='flex justify-center items-center gap-2 !bg-transparent'>
        <p className='text font-semibold text-[10px] lg:text-sm dark:!text-white !text-coal-250'>Wallet</p>
        <p className={cn('text-primary font-normal text-base/normal', addressClassName)}>{truncateAddress(address)}</p>
      </div>
      <div className='flex items-center gap-[14px] dark:!bg-transparent [&_button]:dark:!bg-transparent'>
        <button
          className='flex justify-center items-center gap-[14px] !bg-transparent'
          onClick={() => {
            copyHandler();
          }}
        >
          <CopyIcon className='!fill-primary dark:[&_path]:!fill-primary w-3' />
        </button>
        {children}
      </div>
    </div>
  );
};

export const WalletInfoUIMin: React.FC<IWalletInfoUIProps> = ({ address, className, children }: IWalletInfoUIProps) => {
  const { copyHandler } = useCopy(address);

  return (
    <div className={cn('flex items-center justify-between !bg-light-purple gap-3 dark:!bg-coal rounded pb-[7px] pt-[9px] px-[10px]', className)}>
      <div className='flex justify-center items-center gap-2 !bg-transparent'>
        <p className='font-semibold text-[10px] dark:!text-white !text-coal-250'>Wallet</p>
        <p className='text-primary font-normal text-[10px]'>{truncateAddress(address)}</p>
      </div>
      <div className='flex items-center gap-[14px] dark:!bg-transparent [&_button]:dark:!bg-transparent'>
        <button
          onClick={() => {
            copyHandler();
          }}
        >
          <CopyIcon className='!fill-primary dark:[&_path]:!fill-primary size-3' />
        </button>
        {children}
      </div>
    </div>
  );
};

export const UserWalletInfo: React.FC<IUserWalletInfoUIProps> = ({ className, addressClassName, children }) => {
  const { address } = useAccount();

  if (!address) {
    return null;
  }

  return (
    <WalletInfoUI address={address} className={className} addressClassName={addressClassName}>
      {children}
    </WalletInfoUI>
  );
};

export const WalletInfoAlternate: React.FC<IWalletInfoProps> = ({ className }) => {
  const { address } = useAccount();
  const { copyHandler } = useCopy(address);

  if (!address) return null;

  return (
    <div className={cn('flex items-center justify-between !bg-light-purple gap-2 dark:!bg-jet-black ss:p-3 rounded-[2px] px-[10px] py-2', className)}>
      <span className='text-[10px]/3 font-semibold'>Wallet</span>
      <p className='text-coal-250 dark:text-white font-normal text-[10px]/3 font-space-mono'>{truncateAddress(address)}</p>
      <div className='gap-4 h-5'>
        <button
          className='size-[13px] active:scale-95 hover:opacity-75'
          onClick={() => {
            copyHandler();
          }}
        >
          <DuplicateIcon className='fill-coal-250 [&_path]:!fill-coal-250 dark:[&_path]:!fill-white w-full h-full' />
        </button>
      </div>
    </div>
  );
};

const WalletInfo: React.FC<IWalletInfoProps> = ({ className }) => {
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  if (!address) {
    return null;
  }

  return (
    <UserWalletInfo className={className}>
      {isConnected && (
        <button onClick={() => disconnect()} className='lg:hidden'>
          <CancelIcon />
        </button>
      )}
    </UserWalletInfo>
  );
};

export default WalletInfo;
