import React, { useEffect, useRef, useState } from 'react';

import dynamic from 'next/dynamic';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useAccount } from 'wagmi';

import SettingsIcon from '@/assets/icons/icon-setting.svg';
import Logo from '@/assets/icons/logo.svg';
import ChangeThemeButton from '@/components/shared/buttons/ChangeThemeButton';
import { useAppDispatch } from '@/hooks/redux';
import useAuthenticate from '@/hooks/useAuthenticate';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { fetchUserDetail } from '@/redux/wallet/userSlice';
import { pagesHeader } from '@/utils/constant';
import { cn } from '@/utils/helper';

import SideBarNav from './SideBarNav';
import { WalletModal } from '../../dashboard/portfolio/WalletModal';
import { Loader } from '../../Loader';

const AlertsModal = dynamic(() => import('../../dashboard/alerts/AlertsModal'), { loading: () => <Loader /> });
const AccountSettings = dynamic(() => import('../AccountSettings'), { loading: () => <Loader /> });

interface INavbarProps {
  CustomNavbar?: React.ReactNode;
}

const Navbar: React.FC<INavbarProps> = ({ CustomNavbar }) => {
  const { address } = useAccount();
  const pathname = usePathname();
  const [seeWalletModal, setWalletModal] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const [settings, setSettings] = useState(false);
  const [navbar, setNavbar] = useState<boolean>(false);
  const settingsRef = useRef<HTMLDivElement>(null);
  const alertsRef = useRef<HTMLDivElement>(null);
  const alertModalRef = useRef<HTMLButtonElement>(null);
  const nestedHeader = pagesHeader.find((page) => {
    if (page.path.includes('/loans')) {
      return pathname?.includes('/loans');
    }
    return page.path.includes(pathname);
  });
  const ComponentIcon = nestedHeader?.icon ?? <></>;
  const dispatch = useAppDispatch();
  const { isAuth } = useAuthenticate();

  const walletModalHandler = () => {
    window.scrollTo(0, 0);
    setWalletModal((prev) => {
      if (!prev) {
        document.body.style.overflowY = 'hidden';
      }
      return !prev;
    });
  };

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchUserDetail({ address: address ?? '' }));
    }
  }, [address, dispatch, isAuth]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => {
        if (window.scrollY >= 66) {
          setNavbar(true);
        } else {
          setNavbar(false);
        }
      });
    }
  }, []);

  useOnClickOutside(settingsRef, () => setSettings(false));
  useOnClickOutside(alertsRef, () => setAlertShow(false), alertModalRef);

  const handleOpenSettings = () => {
    setSettings((prev) => !prev);
  };

  return (
    CustomNavbar || (
      <div id='navbar' className='sticky bg-white dark:bg-jet-black top-0 z-20 flex items-center h-[81px] border-b border-gray-200 dark:border-coal-border pr-[26px] justify-between'>
        <div className={`hidden lg:block ${navbar ? 'backdrop-blur-[273px]' : ''}`}>
          {alertShow && (
            <div ref={alertsRef}>
              <AlertsModal />
            </div>
          )}
          {nestedHeader ? (
            <div className='pl-6 flex items-center gap-2'>
              <ComponentIcon className={cn('size-[26px] [&_path]:fill-primary dark:[&_path]:fill-primary-blue-100 dark:[&_circle]:fill-primary/15 [&_circle]:fill-primary/15')} />
              {typeof nestedHeader.label === 'string' ? (
                <span className='text-xl/none dark:!text-white !text-coal-250 font-medium'>{nestedHeader.label}</span>
              ) : (
                <h1 className='text-xl/none dark:!text-white !text-coal-250 font-medium'>
                  {nestedHeader.label[0]} <span className='text-xl/none text-primary font-medium'>{nestedHeader.label[1]}</span>
                </h1>
              )}
            </div>
          ) : null}
        </div>
        <div className='flex pl-[27px] lg:hidden'>
          <Logo />
        </div>
        {isAuth ? (
          <SideBarNav />
        ) : (
          <div className='flex gap-3 text-sm font-medium items-center'>
            <Link href='/login' className='bg-primary rounded text-white px-6 py-3'>
              Sign up
            </Link>
            <Link href='/login' className='bg-primary/15 text-sm font-medium rounded text-primary px-6 py-3'>
              Login
            </Link>
            <ChangeThemeButton />
            {isAuth && (
              <button className='w-10 h-10 rounded-full bg-jet-black-100 flex items-center justify-center'>
                <SettingsIcon />
              </button>
            )}
          </div>
        )}
        {seeWalletModal && <WalletModal isModalShow={walletModalHandler} />}
        {settings && (
          <div ref={settingsRef}>
            <AccountSettings isShow={handleOpenSettings} />
          </div>
        )}
      </div>
    )
  );
};

export default Navbar;
